html body .select2.select2-container {
    // min-width: 194px;
    z-index: 5;

    .select2-selection {
        height: auto;
        border: 0;
        background-color: transparent;

        &__rendered {
            @include button-reset;
            border-radius: 5px;
            font-size: 14px;
            line-height: 1.714285714;
            font-weight: 400;
            border: solid 1px $black;
            padding: 8px 40px 6px 20px;
            background-color: $white;
            color: $black;
            transition: all $baseDuration $baseEase;
            display: inline-flex;
            justify-content: space-between;
            // min-width: 194px;
            text-align: left;
            // max-width: 194px;
            white-space: nowrap;
            text-overflow: ellipsis;
            display: block;
            overflow: hidden;
            position: relative;
            transition: 0ms;

            &::after {
                content: get-icon-chevron-down($black);
                display: inline-block;
                width: 17px;
                height: 9px;
                margin-left: auto;
                position: absolute;
                right: 20px;
                top: 9px;
            }
        }

        &__arrow {
            display: none;
        }
    }

    &--open {
        .select2-selection__rendered {
            border: solid 1px $primaryColor;
        }
    }

    // &--open {
    //     .select2-selection__rendered {
    //         box-shadow: 0 2px 2px 0 rgba($black, 0.25);
    //     }
    // }
}

.gfield_error {
    .select2-selection__rendered {
        border-color: $errorColor !important;
    }
}

.select2-container {
    z-index: 1;

    // .gfield & {
    //     width: 100%;
    // }
}

.gfield,
.mollie-payment-form__field-group {
    .select2-container {
        width: 100%;

        &:not(.select2-container--open) {
            // opacity: 0.5;
            .select2-selection__rendered {
                border: solid 1px $gray;
                background-color: $gray;
                // color: #868686;
            }
        }
    }
}

html body {
    .select2-dropdown {
        border: solid 1px $primaryColor;
        border-radius: 5px;
        overflow: hidden;
        margin-top: -3px;
        // top: 32px;
        // max-width: 194px;

        &--above {
            border-radius: 5px 5px 0 0 !important;
            margin-top: 3px;

            .select2-results__options {
                // box-shadow: inset 0 -5px 2px 0 rgba($black, 0.25);
            }
        }
    }

    .select2-results {
        &__options {
            padding-bottom: 8px;
            padding-top: 8px;
            // box-shadow: inset 0 5px 2px 0 rgba($black, 0.25);
        }

        &__option {
            font-size: 14px;
            display: flex;
            align-items: flex-end;
            padding: 7px 20px;
            font-weight: 400;
            line-height: 1.4;
            transition: all ease 80ms;

            &::after {
                content: get-icon-arrow-right($primaryColor);
                width: 12px;
                height: 10px;
                display: inline-block;
                margin-bottom: 8px;
                margin-left: auto;
                opacity: 0;
                min-width: 12px;
                transition: all ease 80ms;
            }
        }

        &__option--highlighted,
        &__option--selected {
            color: $primaryColor !important;
            background-color: transparent !important;
            // font-weight: 500;

            &::after {
                opacity: 1;
            }
        }

        &__option--selected {
            &::after {
                opacity: 0;
            }

            &:hover {
                &::after {
                    opacity: 1;
                }
            }
        }
    }

    .gfield {
        .select2-dropdown {
            max-width: 100%;
        }

        .select2.select2-container {
            min-width: 100%;

            .select2-selection {
                &__rendered {
                    min-width: 100%;
                    max-width: 100%;
                }
            }
        }
    }
}
