.front-page-slider {
    margin-top: 70px;
    overflow: hidden;

    &__title {
        @include font-style-heading-1;
        margin-bottom: 25px;
    }

    .swiper-container {
        overflow: visible;
    }

    .swiper-wrapper {
        transition-timing-function: linear !important;
    }

    .teaser-programme-grid {
        margin-bottom: 0;
    }

    .swiper-button-next,
    .swiper-button-prev {
        background-color: $white;
        width: 64px;
        height: 64px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $black;
        border-radius: 100%;
        background-image: none;
        transition: transform 0.3s ease;
        margin-top: -32px;

        svg {
            width: 30px;
            height: 30px;
        }

        &:hover {
            transform: scale(1.1);
        }
    }
}
