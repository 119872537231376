.header-carousel {
    @include background-cover;
    color: $white;
    position: relative;
    border-radius: 0 0 0 140px;
    overflow: hidden;

    &__image,
    &__image-mobile {
        @include background-cover;
        @include absolute-cover;
    }

    &__image-mobile {
        display: none;
    }

    &__slide {
        @include background-cover;
        position: relative;

        &::after {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            display: block;
            content: '';
            background: linear-gradient(0deg,
            rgba(0, 0, 0, 0.8) -16.79%,
            rgba(0, 0, 0, 0) 29.01%),
                linear-gradient(180deg,
            rgba(0, 0, 0, 0.8) -22.63%,
            rgba(0, 0, 0, 0) 33.3%);
            height: 100%;
        }
    }

    &__container {
        display: flex;
        flex-direction: column;
        padding: 85px 0 46px;
        min-height: 548px;
        justify-content: flex-end;
        align-items: flex-end;
    }

    &__content {
        // margin-top: auto;
        position: relative;
        z-index: 1;
        margin-top: 20px;
        margin-right: 20px;
    }

    &__button {
        margin-bottom: 5px;

        &:first-child {
            @include button-style--ghost;
        }

        &:last-child {
            @include button-style--white;
        }
    }

    &__text {
        @include font-style-paragraph-1;
        max-width: 554px;
    }

    &__name {
        @include font-style-heading-6;
        margin-top: 5px;
        font-weight: 400;
    }

    &__terms {
        margin-top: 7px;
    }

    // &__text {
    //     @include font-style-paragraph-3;
    //     font-weight: 500;
    //     max-width: 430px;
    //     margin-top: 10px;
    // }

    &__buttons {
        margin-top: 22px;
    }

    &__button {
        margin-right: 15px;

        &:last-child {
            margin-right: 0;
        }
    }

    @include respond-to('tablet') {
        border-radius: 0 0 0 80px;
    }

    @include respond-to('mobile') {
        border-radius: 0 0 0 55px;

        // &__text {
        //     display: none;
        // }

        // &__container {
        //     min-height: 90vh;
        // }

        &__container {
            min-height: 400px;
        }

        &__image,
        &__image-mobile {
            @include background-cover;
            @include absolute-cover;
        }

        &__image {
            display: none;
        }

        &__image-mobile {
            display: block;
        }
    }
}

// .header-basic--fullscreen {
//     .header-basic__container {
//         min-height: 100vh;
//     }

//     .header-basic__logo {
//         display: block;
//     }

//     @include respond-to('mobile') {
//         .header-basic__container {
//             min-height: 90vh;
//         }
//     }
// }

.header-carousel--left-aligned {
    .header-carousel__container {
        margin-right: auto;
        justify-content: flex-end;
        align-items: flex-start;
    }

    .header-carousel__content {
        display: flex;
        flex-direction: column;
    }

    .header-carousel__name {
        @include font-style-heading-3;
        order: 1;
        margin-bottom: 10px;
        margin-top: 0;
    }

    .header-carousel__text {
        @include font-style-paragraph-3;
        order: 2;
        max-width: 430px;
    }

    .header-carousel__buttons {
        order: 3;
    }
}
