.relational-partners {
    margin: 150px 0;

    // &[data-section].closed {
    //     margin-bottom: 200px;
    // }

    // &__container {
    // }

    &__title {
        margin-bottom: 27px;
    }

    &__grid {
        padding: 0 20px;
    }

    &__logos {
        @include flex-grid(4, 44px);
        // padding: 40px 25px 0;
        margin-top: 40px;
    }

    &__logo {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin-bottom: 60px;
    }

    &__column {
        width: 100%;
        flex-grow: 1;
        max-width: 567px;
    }

    &__subtitle {
        @include font-style-heading-5;
        margin-bottom: 25px;
    }

    &__text {
        @include font-style-paragraph-2;
    }

    @include respond-to('tablet') {
        margin: 120px 0;

        &__title {
            margin-bottom: 17px;
        }

        .relational-partners__grid {
            padding: 0 12px;
        }

        .relational-partners__column {
            &:nth-child(2) {
                display: none;
            }
        }

        .relational-partners__text {
            max-width: 509px;
        }

        .relational-partners__subtitle {
            margin-bottom: 15px;
        }

        &__logos {
            @include flex-grid(3, 44px);
            // padding: 40px 25px 0;
            margin-top: 40px;
        }
    }

    @include respond-to('mobile') {
        margin: 80px 0;

        .relational-partners__grid {
            padding-top: 0;
            // margin-top: -30px;
        }

        &__logos {
            @include flex-grid(2, 54px, 24px);
            // padding: 40px 25px 0;
            margin-top: 20px;
        }

        &__logo {
            margin-bottom: 4px;
        }

        &__text {
            @include font-style-paragraph-4;
        }
    }
}
