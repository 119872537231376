.content-fold-out {
    margin-bottom: 44px;

    &__body {
        padding: 0 20px;
    }

    &__grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 30px;
    }

    &__text {
        margin: 20px 0;
    }

    &__item-button {
        background-color: transparent;
        padding: 0;
        border: 0;
        font-family: $paragraphFont;

        .icon {
            transition: all 0.2s ease;
            margin-left: 2px;
        }

        &:hover {
            .icon {
                margin-left: 6px;
            }
        }
    }

    &__content {
        margin-top: 40px;
        padding-top: 40px;
        padding-bottom: 40px;
        position: relative;
        display: none;

        &.opened {
            display: block;
        }

        &--mobile.opened {
            display: none;
        }
    }

    &__content-close {
        position: absolute;
        top: 0;
        right: 0;
        background-color: transparent;
        border: 0;
        padding: 0;
        margin: 0;
    }

    &__content-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 60px;
    }

    @include respond-to('mobile') {
        &__grid {
            grid-template-columns: 1fr;
        }

        &__content-grid {
            grid-template-columns: 1fr;
            gap: 40px;
        }

        &__body {
            padding: 0 12px;
        }

        &__content {
            margin-top: 0;
            padding-top: 32px;

            &.opened {
                display: none;
            }

            &--mobile.opened {
                display: block;
            }
        }
    }
}

.content-fold-out {
    .header-subsection__toggle {
        display: none;
    }

    .header-subsection {
        padding-bottom: 0;
    }
}
