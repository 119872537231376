.rsvp-step-4-template {
    &__body {
        padding: 36px 20px 0;
        margin-bottom: 140px;
    }

    &__form {
        max-width: 570px;
    }

    .form-text {
        h5 {
            margin-bottom: 1.6rem;

            &.small-margin {
                margin-top: 38px;
                margin-bottom: 5px;
            }
        }

        p {
            @include font-style-paragraph-4;
        }
    }

    @include respond-to('tablet') {
        &__body {
            padding: 28px 12px 0;
            margin-bottom: 120px;
        }

        &__form {
            max-width: 100%;
        }

        .form-text {
            h5 {
                margin-bottom: 1.1rem;

                &.small-margin {
                    margin-top: 38px;
                    // margin-bottom: 10px;
                }
            }
        }

        .gfield_html {
            margin-bottom: 15px;
        }
    }

    @include respond-to('mobile') {
        &__body {
            padding: 19px 12px 0;
            margin-bottom: 80px;
        }
    }
}

.donate-template {
    .header-subsection {
        border-top: 0;

        &__toggle {
            display: none;
        }
    }
}
