.content-text-and-details {
    margin-bottom: 85px;

    &__body {
        padding: 35px 20px 0;
    }

    &__grid {
        @include flex-grid(2, 2px);
    }

    &__column {
        &:nth-child(1) {
            padding-right: 110px;
        }

        &:nth-child(2) {
            padding-top: 65px;
        }
    }

    &__tags {
        .tag {
            cursor: pointer;

            &:hover {
                background-color: $primaryColor;
                color: $white;
                text-decoration: none;
            }
        }
    }

    &__share-mobile {
        display: none;
    }

    &__title {
        @include font-style-heading-5;
        margin-bottom: 28px;
    }

    &__intro {
        @include font-style-paragraph-3;
        margin-bottom: 25px;
    }

    &__subtitle {
        @include font-style-heading-6;
        margin-bottom: 5px;
    }

    &__text {
        p {
            @include font-style-paragraph-2;
        }
    }

    &__button {
        margin-top: 25px;
        border-color: $primaryColor;
        color: $primaryColor;
    }

    // &__items {
    // }

    &__label {
        font-weight: 700;
        list-style: none;
        display: inline-block;
    }

    &__detail {
        margin-left: -5px;
        max-width: 575px;
    }

    .feature-share {
        margin-left: -5px;
        margin-top: 58px;

        &:first-child {
            margin-top: 0;
        }
    }

    @include respond-to('tablet') {
        margin-bottom: 38px;

        &__body {
            padding-top: 28px;
        }

        &__grid {
            flex-direction: column;
        }

        &__column {
            padding-top: 0 !important;
            padding-right: 0 !important;

            .feature-share {
                // display: none;
            }

            &:nth-child(1) {
                .feature-share {
                    display: none;
                }
            }
        }

        &__text,
        &__intro {
            max-width: 485px;
        }

        &__detail {
            margin-left: 0;
        }

        .feature-share {
            margin-left: 0;
            margin-top: 40px;
        }

        .tags {
            margin-left: 0;
        }

        &__share-mobile {
            display: block;
            margin-top: 25px;
        }
    }

    @include respond-to('mobile') {
        &__body {
            padding: 19px 12px 0;
        }

        &__text {
            p {
                @include font-style-paragraph-4;
            }
        }
    }
}
