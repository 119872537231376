.form-donate {
    margin: 140px 0 150px;

    // &__container {
    // }

    &__title {
        margin-bottom: 27px;
    }

    &__body {
        padding: 0 20px;
        max-width: 50%;
    }

    form {
        // display: flex;
        // padding: 0;
        // margin: 0;
        align-items: flex-start;
        margin-top: 30px;

        .select2 {
            width: 100% !important;
        }
    }

    .mollie-payment-form__field-group:last-child {
        margin-top: 40px;
    }

    &__text {
        @include font-style-paragraph-2;
        max-width: 566px;
    }

    @include respond-to('tablet') {
        margin: 120px 0;

        &__body {
            max-width: 100%;
        }

        &__title {
            margin-bottom: 17px;
        }

        &__grid {
            padding: 0 12px;
        }

        &__text {
            // max-width: 509px;
        }
    }

    @include respond-to('tablet') {
        margin: 80px 0;

        &__body {
            padding: 0 12px;
        }
    }

    @include respond-to('mobile') {
        &__text {
            @include font-style-paragraph-4;
        }
    }
}
