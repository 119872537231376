.content-list-faq {
    margin: 70px 0;

    &__title {
        // @include font-style-heading-1;
        margin-bottom: 0;
    }

    @include respond-to('tablet') {
        margin: 60px 0;
    }

    @include respond-to('tablet') {
        margin: 40px 0;
    }
}
