.meet-our-people-template {
    margin: 90px 0 70px;

    &__header {
        .header-section {
            border-bottom: 0;
        }

        .header-section__close {
            pointer-events: none;
            opacity: 0;
        }
    }

    .select2-container {
        z-index: 999;
    }

    @include respond-to('tablet') {
        margin: 60px 0 60px;

        &__header {
            .header-section__open {
                pointer-events: none;
                opacity: 0;
            }
        }

        .form-filters__checkboxes {
            flex-wrap: wrap;
        }
    }

    @include respond-to('mobile') {
        margin: 45px 0 40px;
    }
}

.meet-our-people-template__wrapper {
    margin-top: 40px;
}

// Filter styling
.page-template-meet-our-people-template.is-filtering
    .meet-our-people-template__wrapper {
    .relational-people {
        display: none;
    }

    .relational-people.is-active {
        display: block;
    }

    .teaser-person {
        display: none;
    }

    .teaser-person.is-active {
        display: block;
    }
}
