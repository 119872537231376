@use 'sass:math';

.header-basic {
    @include background-cover;
    color: $white;
    position: relative;
    border-radius: 0 0 0 140px;
    overflow: hidden;

    position: relative;

    &::before {
        display: block;
        content: '';
        width: 100%;
        padding-top: math.div(250, 599) * 100%;
    }

    > .inner {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    &__image {
        @include background-cover;
        @include absolute-cover;
    }

    &__image--mobile {
        display: none;
    }

    &__container {
        display: flex;
        flex-direction: column;
        padding: 85px 0 46px;
        min-height: 100%;
    }

    &__logo {
        margin-bottom: 100px;
        display: none;
        z-index: 1;
    }

    &__content {
        margin-top: auto;
        position: relative;
        z-index: 1;
    }

    &__button {
        margin-bottom: 5px;

        &:first-child {
            @include button-style--ghost;
        }

        &:last-child {
            @include button-style--white;
        }
    }

    &__title {
        @include font-style-heading-4;
    }

    &__subtitle {
        @include font-style-heading-5;
        font-weight: 400;
        max-width: 550px;
    }

    &__terms {
        margin-top: 7px;
    }

    &__text {
        @include font-style-paragraph-3;
        font-weight: 500;
        max-width: 430px;
        margin-top: 10px;
    }

    &__buttons {
        margin-top: 22px;
    }

    &__button {
        margin-right: 15px;

        &:last-child {
            margin-right: 0;
        }
    }

    &::after {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        display: block;
        content: '';
        background: linear-gradient(0deg,
        rgba(0, 0, 0, 0.8) -16.79%,
        rgba(0, 0, 0, 0) 29.01%),
            linear-gradient(180deg,
        rgba(0, 0, 0, 0.8) -22.63%,
        rgba(0, 0, 0, 0) 33.3%);
        height: 100%;
    }

    @media (max-height: 500px) {
        &__container {
            min-height: calc(var(--vh, 1vh) * 90);
        }
    }

    @include respond-to('tablet') {
        border-radius: 0 0 0 80px;
    }

    @include respond-to('mobile') {
        border-radius: 0 0 0 55px;

        &__container {
            min-height: calc(var(--vh, 1vh) * 90);
        }

        &__text {
            display: none;
        }

        &__image {
            display: none;
        }

        &__image--mobile {
            display: block;
        }
    }
}

.header-basic--fullscreen {
    &::after {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        display: block;
        content: '';
        background: linear-gradient(0deg,
        rgba(0, 0, 0, 0.57) 24.59%,
        rgba(0, 0, 0, 0) 100%);
        padding-bottom: 23.194444442%;
        height: auto;
    }

    &::before {
        padding-top: calc(100 * var(--vh, 1vh));
    }

    .header-basic__container {
        min-height: calc(100 * var(--vh, 1vh));
    }

    .header-basic__logo {
        display: block;
    }

    @include respond-to('mobile') {
        .header-basic__container {
            min-height: calc(100 * var(--vh, 1vh));
        }
    }
}

.header-basic--testimonial {
    .header-basic__container {
        align-items: end;
        justify-content: center;
    }

    .header-basic__content {
        margin-top: 0;
    }
}

.header-basic--testimonial.header-basic--flipped {
    border-radius: 0 0 140px;

    .header-basic__container {
        align-items: start;
    }
}

.template-page__flex-components .header-basic {
    margin: 170px 0;

    @include respond-to('tablet') {
        margin: 120px 0;
    }

    @include respond-to('mobile') {
        margin: 80px 0;
    }
}

.template-page__flex-components .header-basic::before {
    padding-top: 30%;
}
