.education-template {
    margin: 90px 0 70px;
    overflow: hidden;

    &:has(.header-basic) {
        margin-top: 0 !important;
    }

    &__title {
        margin-bottom: 46px;
    }

    .education-project-slider {
        .content-wysiwyg__text {
            max-width: 100%;
        }

        .swiper-container {
            overflow: visible;
        }

        .swiper-wrapper {
            transition-timing-function: linear !important;
        }

        .teaser-programme-grid__title {
            @include font-style-heading-4;
        }

        .swiper-education-slider {
            margin-left: 0;
            max-width: 100%;
        }

        .teaser-programme-grid {
            margin-bottom: 0;
        }

        .swiper-button-next,
        .swiper-button-prev {
            background-color: $white;
            width: 64px;
            height: 64px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $black;
            border-radius: 100%;
            background-image: none;
            transition: transform 0.3s ease;
            margin-top: -32px;

            svg {
                width: 30px;
                height: 30px;
            }

            &:hover {
                transform: scale(1.1);
            }
        }
    }

    .facetwp-template {
        margin-top: 40px;
    }

    &__items .section-title {
        @include font-style-heading-1;
        margin-top: 72px;
    }

    &__grid-title {
        @include font-style-heading-5;
        margin-bottom: 40px;
        border-top: 1px solid $black;
        padding-top: 34px;
        padding-left: 20px;

        @include respond-to('tablet') {
            padding-top: 22px;
        }

        @include respond-to('mobile') {
            padding-top: 13px;
            padding-left: 12px;
            margin-bottom: 20px;
        }
    }

    .form-filters {
        @include respond-to('mobile') {
            padding-left: 12px;
        }
    }

    [data-view='list'],
    [data-view='grid'] {
        display: none;

        &.is-active {
            display: block;
        }
    }

    [data-view='list'] {
        margin-bottom: 60px;
    }

    [data-view='grid'] {
        display: none;
        flex-flow: row wrap;
        margin-left: -10px;
        margin-right: -10px;

        &.is-active {
            display: flex;
        }

        .teaser-programme-grid {
            width: 40%;
            flex-grow: 1;
            margin-right: 10px;
            margin-left: 10px;
            margin-bottom: 20px;
            max-width: calc(50% - 20px);

            &__title {
                max-width: 405px;
            }

            &:nth-child(3n) {
                @include aspect-ratio(1240, 453);
                width: 100%;
                max-width: 100%;
            }
        }
    }

    &__buttons {
        margin-top: 40px;
        text-align: center;

        .facetwp-facet {
            margin-bottom: 60px;
        }
    }

    &__button {
        @include button-style--white;
    }

    &__views {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        // margin-left: auto;
        pointer-events: none;
        margin-top: -20px;
    }

    &__view {
        padding: 0;
        background-color: transparent;
        margin: 0;
        outline: none;
        box-shadow: none;
        border: 0;
        width: 20px;
        height: 20px;
        margin-right: 20px;
        pointer-events: all;

        .svg__fill {
            fill: $black;
        }

        &:last-child {
            margin-right: 0;
        }

        &.is-active {
            .svg__fill {
                fill: $primaryColor;
            }
        }
    }

    @include respond-to('tablet') {
        margin: 95px 0 50px;

        &__title {
            margin-bottom: 35px;
        }

        .facetwp-template {
            margin-top: 25px;
        }

        &__buttons {
            margin-top: 60px;

            .facetwp-facet {
                margin-bottom: 40px;
            }
        }

        [data-view='grid'] {
            .teaser-programme-grid {
                width: 100%;
                max-width: 100%;

                &:nth-child(3n) {
                    @include aspect-ratio(1240, 400);
                    width: 100%;
                    max-width: 100%;
                }
            }
        }
    }

    @media (max-width: 900px) {
        &__views {
            margin-top: 20px;
        }
    }

    @include respond-to('mobile') {
        margin: 45px 0 40px;

        &__title {
            margin-bottom: 25px;
        }

        .facetwp-template {
            margin-top: 25px;
        }

        &__buttons {
            margin-top: 40px;
            padding: 0 10px;
        }

        .button {
            width: 100%;
        }
    }
}
