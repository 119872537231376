/* stylelint-disable */
//
// Variables
// --------------------------------------------------

// Fonts
// -------------------

$headingFont: 'Neue Montreal', sans-serif;
$paragraphFont: 'Neue Montreal', sans-serif;

// Colours
// -------------------
$black: #000;
$white: #fff;

$primaryColor: #814bf7;
$secondaryColor: #9d6ab4;
$gray: #dbdbdb;
$mediumGray: #c4c4c4;
$lightGray: #f7f7f7;
$dark: #1b1b1a;

$textColor: $black;
$baseColor: #eee;

$socialFacebook: #3b5998;
$socialTwitter: #00aced;
$socialInstagram: #3f729b;
$socialYoutube: #cd201f;

$errorColor: #ff0000;
$successColor: #0a0;
$actionColor: $primaryColor;
$baseFontSize: 16;
$baseDuration: 150ms;
$baseEase: ease-in-out;
$baseTransition: all $baseDuration $baseEase;
$baseBazier: all 0.5s cubic-bezier(0.19, 1, 0.22, 1);

// Breakpoints
// -------------------

$breakpoints: (
    'mobile': (
        max-width: 736px,
    ),
    'tablet': (
        max-width: 1024px,
    ),
    'desktop': (
        min-width: 1240px,
    ),
);

// Include gutter on outside
$gutterOnOutside: true;

// Breakpoints Grid Settings
// -------------------

$grid-settings: (
    base: (
        container-columns: 12,
        gutter: 1%,
        max-width: calc(100% - 100px),
    ),
    desktop: (
        container-columns: 12,
        gutter: 1%,
        max-width: 1200px,
    ),
    tablet: (
        container-columns: 12,
        gutter: 1%,
        max-width: calc(100% - 80px),
    ),
    mobile: (
        container-columns: 1,
        gutter: 5%,
        max-width: calc(100% - 40px),
    ),
);
