.teaser-news {
    display: flex;
    margin-bottom: 60px;
    position: relative;

    &:last-child {
        margin-bottom: 0;
    }

    &__column {
        width: 50%;
        flex-grow: 1;

        &:nth-child(1) {
            margin-right: 25px;
            border-top: solid 1px $black;
            padding-top: 36px;
            padding-left: 20px;
        }
    }

    &__title {
        @include font-style-heading-5;
        max-width: 293px;
        transition: $baseBazier;

        &--mobile {
            @include absolute-cover;
            display: none;
            color: $white;
            z-index: 2;
            padding: 9px;
            max-width: 300px;
            width: 100%;
        }
    }

    &__terms {
        margin-bottom: 16px;
    }

    &__text {
        @include font-style-paragraph-3;
        max-width: 380px;
        margin-top: 28px;
        transition: all ease-in-out 100ms;
    }

    &__image {
        @include aspect-ratio(610, 346);
        border-radius: 5px;
        overflow: hidden;
        position: relative;
        transition: $baseBazier;
    }

    &__image-inner {
        @include absolute-cover;
        @include background-cover;
        transition: all 700ms cubic-bezier(0.19, 1, 0.22, 1);
    }

    &__readmore {
        @include button-style--readmore;
        display: inline-block;
        margin-top: 32px;
    }

    &__link {
        @include absolute-cover;
        z-index: 2;
    }

    &:hover {
        color: $primaryColor;

        .teaser-news__image {
            border-radius: 5px 5px 5px 120px;
            // box-shadow: 0 4px 2px 0 rgba($black, 0.5);
        }

        .teaser-news__image-inner {
            transform: scale(1.1);
        }

        .teaser-news__label {
            transform: translateY(0);
            opacity: 1;
        }

        .teaser-news__readmore {
            @include button-style--readmore-purple;
        }

        .teaser-news__readmore {
            &::after {
                transform: translateX(5px);
            }
        }
    }

    @include respond-to('tablet') {
        margin-bottom: 80px;
        flex-direction: column;

        .teaser-news__column {
            width: 100%;
            flex-grow: 1;
            order: 2;
            border-top: 0;

            &:nth-child(1) {
                padding: 0 12px;
            }

            &:nth-child(2) {
                width: 100%;
                order: 1;
                margin-bottom: 20px;
            }
        }

        .teaser-news__image {
            @include aspect-ratio(718, 407);
            height: 100%;

            &::before {
                @include absolute-cover;
                content: '';
                display: block;
                background: linear-gradient(180deg,
                rgba(0, 0, 0, 0.8) 0%,
                rgba(0, 0, 0, 0) 52.33%);
                z-index: 1;
            }
        }

        .teaser-news__terms {
            margin-bottom: 10px;
        }

        &__title {
            display: none;

            &--mobile {
                @include font-style-heading-3;
                display: block;
                color: $white;
            }
        }

        .teaser-news__text {
            margin-top: 15px;
            max-width: 100%;
        }

        .teaser-news__readmore {
            margin-top: 20px;
        }

        &:hover {
            .teaser-news__image {
                border-radius: 5px 5px 5px 80px;
            }
        }
    }

    @include respond-to('mobile') {
        margin-bottom: 40px;

        .teaser-news__image {
            @include aspect-ratio(300, 300);
        }

        .teaser-news__column {
            // padding-top: 20px;
            // padding-left: 10px;

            // margin-right: 0;

            // &:nth-child(2) {
            //     display: none;
            // }
        }

        // .teaser-news__terms {
        // }

        // .teaser-news__text {
        // }

        // .teaser-news__readmore {
        // }

        &:hover {
            .teaser-news__image {
                border-radius: 5px 5px 5px 55px;
            }
        }
    }
}
