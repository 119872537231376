//
// Pagination
// --------------------------------------------------
/* stylelint-disable */
section.pagination .pages {
    margin-top: 1em;
    margin-bottom: 3em;
    padding: 12px 5px 11px;
    background: #ebebeb;
    text-align: center;
    font-size: 0.9em;
    text-transform: uppercase;
    font-family: $headingFont;
    line-height: 1.2;

    > a {
        padding: 0 1em;
        display: inline-block;

        svg {
            margin-top: 1px;
            display: block;

            polyline {
                stroke: #000;
            }
        }

        &.prev {
            float: left;

            svg {
                float: left;
                margin-right: 7px;
            }

            &.inactive {
                svg {
                    polyline {
                        stroke: #858585;
                    }
                }
            }
        }

        &.next {
            float: right;

            svg {
                float: right;
                margin-left: 7px;
            }

            &.inactive {
                color: #858585;

                svg {
                    polyline {
                        stroke: #858585;
                    }
                }
            }
        }

        &.inactive {
            color: #858585;

            &:hover {
                text-decoration: none;
            }
        }
    }

    .numbers {
        display: inline-block;

        a {
            color: #000;
            padding: 0 4px;
        }

        .current {
            color: #858585;
            padding: 0 4px;
        }
    }
}
