.relational-testimonial {
    margin: 100px 0 85px;

    &__container {
        position: relative;
    }

    &__swiper {
        max-width: 856px;
        margin-left: auto;
        margin-right: auto;
    }

    &__slide {
        text-align: center;
    }

    &__image {
        @include background-cover;
        height: 140px;
        width: 140px;
        border-radius: 50%;
        display: inline-block;
        margin-bottom: 35px;
        box-shadow: 0 2px 2px 0 rgba($black, 0.25);
    }

    &__text {
        @include font-style-heading-4;
        line-height: 1.25;
        font-weight: 500;
    }

    &__name-city {
        @include font-style-heading-6;
        font-weight: 500;
        line-height: 1.25;
        margin-top: 35px;
    }

    &__prev,
    &__next {
        position: absolute;
        top: 60%;
        transform: translateY(-50%);
        cursor: pointer;

        &::after {
            content: get-icon-chevron-left($black);
            width: 22px;
            height: 38px;
            display: block;
        }
    }

    &__prev {
        left: 25px;
    }

    &__next {
        right: 25px;

        &::after {
            content: get-icon-chevron-right($black);
        }
    }

    @media (max-width: 1130px) {
        &__swiper {
            max-width: calc(100% - 154px);
        }
    }

    @include respond-to('tablet') {
        margin: 60px 0;
    }

    @include respond-to('mobile') {
        margin: 40px 0;
        display: none;
    }
}
