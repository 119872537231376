.teaser-programme-grid {
    @include aspect-ratio(610, 604);
    color: $white;
    width: 100%;
    position: relative;
    border-radius: 5px;
    margin-bottom: 20px;
    overflow: hidden;
    transition: $baseBazier;

    &::before {
        @include absolute-cover;
        content: '';
        display: block;
        background: linear-gradient(180deg,
        rgba(0, 0, 0, 0.456) 18.1%,
        rgba(0, 0, 0, 0) 99.03%);
        height: 178px;
        // transform: rotate(180dg);
        z-index: 1;
    }

    &__image {
        @include background-cover;
        @include absolute-cover;
        transition: all 700ms cubic-bezier(0.19, 1, 0.22, 1);
    }

    &__content {
        @include absolute-cover;
        z-index: 2;
        padding: 13px 18px 18px;
        padding-right: 150px;
    }

    &__label {
        @include font-style-paragraph-1;
        position: absolute;
        top: 10px;
        left: 18px;
        margin: 0;
        transform: translateY(-40px);
        transition: $baseBazier;
        opacity: 0;
    }

    &__tags {
        position: absolute;
        top: 18px;
        right: 13px;
        z-index: 2;
        text-align: right;
    }

    &__title {
        @include font-style-heading-3;
        padding-right: 85px;
        transition: $baseBazier;
    }

    &__term {
        @include font-style-paragraph-2;
        margin-top: 4px;
        transition: $baseBazier;
    }

    &__link {
        @include absolute-cover;
        z-index: 2;
    }

    &:hover {
        border-radius: 5px 5px 5px 120px;
        // box-shadow: 0 4px 2px 0 rgba($black, 0.5);

        .teaser-programme-grid__image {
            transform: scale(1.1);
        }

        .teaser-programme-grid__label {
            transform: translateY(0);
            opacity: 1;
        }

        .teaser-programme-grid__title,
        .teaser-programme-grid__term {
            transform: translateY(30px);
        }
    }

    @include respond-to('tablet') {
        @include aspect-ratio(1240, 400);
        min-height: 160px;

        &:hover {
            border-radius: 5px 5px 5px 80px;
        }
    }

    @include respond-to('mobile') {
        .teaser-programme-grid__content {
            padding-right: 120px;
        }

        &:hover {
            border-radius: 5px 5px 5px 55px;
        }
    }
}
