.navigation-breadcrumbs {
    padding: 0;
    margin: 0;
    margin-bottom: 25px;
    margin-top: 35px;

    > span {
        display: block;
    }

    a,
    span {
        @include font-style-paragraph-4;
        display: inline-block;
        // margin-right: 5px;
    }

    a {
        color: $textColor;
        font-weight: 400 !important;

        &:hover {
            text-decoration: none;
            color: $primaryColor;
        }
    }

    &__separator {
        // display: inline-block;
        margin: 0 5px;

        &::after {
            display: inline-block;
            content: get-icon-arrow-right($black);
            width: 12px;
            height: 10px;
            display: inline-block;
        }
    }

    &__item {
        @include font-style-paragraph-4;
        display: inline-block;
        margin-right: 5px;

        &::after {
            display: inline-block;
            content: get-icon-arrow-right($black);
            width: 12px;
            height: 10px;
            display: inline-block;
            margin-left: 5px;
            transition: all 100ms ease;
        }

        &:last-child {
            &::after {
                display: none;
            }
        }
    }

    @include respond-to('mobile') {
        margin-bottom: 20px;

        &__item {
            font-size: 12px;
        }
    }
}
