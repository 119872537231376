@mixin font-inherits {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    li,
    a {
        font-family: inherit;
        font-weight: inherit;
        font-size: inherit;
        line-height: inherit;
        letter-spacing: inherit;
    }

    a {
        font-weight: 500;
    }
}

@mixin font-style-heading-1 {
    @include font-inherits;
    font-size: 80px;
    font-weight: 700;
    font-family: $headingFont;

    @include respond-to('tablet') {
        font-size: 55px;
    }

    @include respond-to('mobile') {
        font-size: 35px;
    }
}

@mixin font-style-heading-2 {
    @include font-inherits;
    font-size: 60px;
    font-weight: 700;
    font-family: $headingFont;

    @include respond-to('tablet') {
        font-size: 40px;
    }

    @include respond-to('mobile') {
        font-size: 30px;
    }
}

@mixin font-style-heading-3 {
    @include font-inherits;
    font-size: 40px;
    font-weight: 700;
    font-family: $headingFont;
    line-height: 1.05;

    @include respond-to('tablet') {
        font-size: 35px;
        line-height: 1;
    }

    @include respond-to('mobile') {
        font-size: 25px;
    }
}

@mixin font-style-heading-4 {
    @include font-inherits;
    font-size: 35px;
    font-weight: 700;
    line-height: 0.914285714;
    font-family: $headingFont;

    @include respond-to('tablet') {
        font-size: 35px;
        line-height: 1;
    }

    @include respond-to('mobile') {
        font-size: 25px;
    }
}

@mixin font-style-heading-5 {
    @include font-inherits;
    font-size: 30px;
    font-weight: 700;
    line-height: 1.066666667;
    font-family: $headingFont;

    @include respond-to('tablet') {
        font-size: 25px;
        line-height: 1;
    }

    @include respond-to('mobile') {
        font-size: 18px;
    }
}

@mixin font-style-heading-6 {
    @include font-inherits;
    font-size: 20px;
    font-weight: 700;
    line-height: 1.3;
    font-family: $headingFont;

    @include respond-to('mobile') {
        font-size: 16px;
        line-height: 1.1625;
    }
}

@mixin font-style-paragraph-1 {
    @include font-inherits;
    font-size: 25px;
    font-weight: 500;
    font-family: $paragraphFont;
    line-height: 1.28;

    @include respond-to('tablet') {
        font-size: 24px;
        line-height: 1.333333333;
    }

    @include respond-to('mobile') {
        font-size: 18px;
        line-height: 1.333333333;
    }
}

@mixin font-style-paragraph-2 {
    @include font-inherits;
    font-size: 18px;
    font-weight: 400;
    font-family: $paragraphFont;
    line-height: 1.555555556;

    @include respond-to('tablet') {
        font-size: 18px;
        line-height: 1.555555556;
    }

    @include respond-to('mobile') {
        font-size: 16px;
        line-height: 1.5;
    }
}

@mixin font-style-paragraph-3 {
    @include font-inherits;
    font-size: 16px;
    font-weight: 400;
    font-family: $paragraphFont;
    line-height: 1.625;

    @include respond-to('tablet') {
        font-size: 18px;
        line-height: 1.555555556;
    }

    @include respond-to('mobile') {
        font-size: 14px;
        line-height: 1.571428571;
    }
}

@mixin font-style-paragraph-4 {
    @include font-inherits;
    font-size: 14px;
    font-weight: 400;
    font-family: $paragraphFont;
    line-height: 1.714285714;

    // @include respond-to('tablet') {
    //     font-size: 14px;
    //     line-height: 1.625;
    // }

    @include respond-to('mobile') {
        font-size: 14px;
        line-height: 1.571428571;
    }
}

@mixin font-style-paragraph-5 {
    @include font-inherits;
    font-size: 12px;
    font-weight: 400;
    font-family: $paragraphFont;
    line-height: 1.5;

    // @include respond-to('tablet') {
    //     font-size: 11px;
    //     line-height: 1.636363636;
    // }

    @include respond-to('mobile') {
        font-size: 11px;
        line-height: 1.636363636;
    }
}
