.news-overview-template {
    margin: 90px 0 70px;

    &__title {
        margin-bottom: 46px;
    }

    .facetwp-template {
        margin-top: 40px;
    }

    &__items .section-title {
        @include font-style-heading-1;
        margin-top: 72px;
    }

    &__buttons {
        margin-top: 40px;
        text-align: center;

        .facetwp-facet {
            margin-bottom: 75px;
        }
    }

    &__button {
        @include button-style--white;
    }

    @include respond-to('tablet') {
        margin: 60px 0 60px;

        &__title {
            margin-bottom: 35px;
        }

        .facetwp-template {
            margin-top: 25px;
        }

        // &__buttons {
        //     margin-top: 60px;

        //     .facetwp-facet {
        //         margin-bottom: 40px;
        //     }
        // }
    }

    @include respond-to('mobile') {
        margin: 45px 0 40px;

        &__title {
            margin-bottom: 25px;
        }

        .facetwp-template {
            margin-top: 25px;
        }

        &__buttons {
            margin-top: 40px;
            padding: 0 10px;
        }

        .button {
            width: 100%;
        }
    }
}
