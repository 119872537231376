.gform_wrapper form {
    .is-hidden {
        display: none;
    }
}

// Hide recaptcha badge
.grecaptcha-badge {
    display: none !important;
}

.gfield-choice-input ~ input {
    margin-top: 1.2rem !important;
}
