.template-page {
    &__flex-components {
        > *:first-child {
            margin-top: 0;

            .header-subsection {
                border-top: 0;
            }

            .header-subsection__toggle {
                display: none;
            }
        }
    }
}
