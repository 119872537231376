.for-you {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0 120px;
    padding: 0 20px;
    margin-bottom: 80px;

    @include respond-to('tablet') {
        grid-template-columns: 1fr;
        gap: 40px;
    }

    @include respond-to('mobile') {
        gap: 20px;
        padding: 0 12px;
    }
}
