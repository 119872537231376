main.single-dancer {
    margin: 160px 0 0;
    @include respond-to('tablet') {
        margin: 95px 0 0;
    }

    @include respond-to('mobile') {
        margin: 75px 0 0;
    }
}

.single-dancer {
    &__flex-components {
        > *:first-child {
            margin-top: 0;

            .header-subsection {
                border-top: 0;
            }

            .header-subsection__toggle {
                display: none;
            }
        }
    }

    .content-text-and-images:first-child .content-text-and-images__body {
        padding-top: 0;
    }

    .content-text-and-images__column {
        &:nth-child(2) {
            padding-top: 0;
        }
    }

    .content-text-and-images__image-source {
        border-radius: 5px 5px 100px;
    }

    @include respond-to('tablet') {
        .content-text-and-images__grid {
            flex-wrap: nowrap;
        }

        .content-text-and-images__column {
            &:nth-child(1) {
                order: 2;
            }

            &:nth-child(2) {
                order: 1;
                margin-bottom: 34px;
            }
        }

        .content-text-and-images__image {
            margin-left: auto;
            margin-right: auto;
            max-width: 544px;
        }

        .content-text-and-images__image-source {
            border-radius: 5px 5px 5px 100px;
        }
    }

    @include respond-to('mobile') {
        .content-text-and-images__image {
            display: flex;
            // align-items: flex-start;
            flex-direction: column;
            position: relative;

            &::after {
                content: '';
                display: block;
                width: 100%;
                padding-bottom: 100%;
            }
        }

        .content-text-and-images__image-source {
            @include absolute-cover;
            border-radius: 5px;
            object-fit: cover;
        }
    }
}
