.teaser-contact {
    padding: 40px 0;
    border-bottom: solid 1px rgba($black, 0.2);
    position: relative;
    // overflow: hidden;
    height: 100%;

    &__body {
        display: flex;
        align-items: flex-start;
    }

    &__image {
        @include background-cover;
        @include aspect-ratio(1, 1);
        width: 151px;
        min-width: 151px;
        margin-right: 21px;
        border-radius: 5px 5px 5px 40px;

        &--mobile {
            display: none;
        }
    }

    &__title {
        @include font-style-heading-5;
        margin-bottom: 16px;
    }

    &__name {
        @include font-style-paragraph-3;
        font-weight: 500;
        margin-bottom: 20px;
    }

    &__text {
        @include font-style-paragraph-4;
    }

    @include respond-to('tablet') {
        padding: 30px 0;

        &__image {
            width: 100px;
            min-width: 100px;
        }
    }

    @include respond-to('mobile') {
        padding: 20px 0;
    }

    @media (max-width: 500px) {
        &__body {
            flex-direction: column;
        }

        &__image {
            margin-right: 0;
            margin-bottom: 17px;
            border-radius: 5px;
            display: none;

            &--mobile {
                display: block;
            }
        }

        &__name {
            margin-bottom: 5px;
        }
    }
}
