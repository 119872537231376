.form-filters {
    padding: 0 20px;

    &__filter {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0;
        }

        .page-template-programme-template & {
            margin-bottom: 0;

            &:last-child {
                display: none;
            }
        }

        .page-template-education-template & {
            margin-bottom: 0;

            &:last-child {
                display: none;
            }
        }
    }

    &__label {
        @include font-style-heading-6;
        font-weight: 500;
        min-width: 115px;
    }

    &__checkboxes {
        margin-left: -7px;
        margin-right: 7px;
        margin-top: -7px;
        display: flex;
        align-items: flex-start;
    }

    &__selects {
        margin-left: -4px;
        margin-right: 4px;
        margin-top: -4px;

        .select2 {
            margin: 4px 4px 0;
            max-width: 194px;
            min-width: 194px;
        }
    }

    &__select {
        display: inline-block;
        position: relative;

        &.open-select {
            z-index: 100;
        }
    }

    .button {
        margin: 7px 7px 0;
        background-image: none !important;
        text-transform: capitalize;
    }

    .facetwp-facet {
        margin: 0;
    }

    @include respond-to('tablet') {
        padding: 0;

        &__filter {
            display: block;
        }

        &__label {
            margin-bottom: 20px;
        }

        &__checkboxes,
        &__selects {
            // padding: 0 10px;
        }
    }

    @include respond-to('mobile') {
        &__label {
            margin-bottom: 8px;
        }

        .form-filters__checkboxes {
            margin-left: -4px;
            margin-right: 4px;
            margin-top: -4px;
        }

        .form-filters__checkboxes .button {
            width: auto;
            margin: 4px 4px 0;
        }
    }
}

.page-template-meet-our-people-template .form-filters__checkboxes {
    flex-wrap: wrap;
}
