.tags {
    margin: 0;
    padding: 0;
    margin-left: -5px;
    margin-top: -5px;
    margin-bottom: -5px;
}

.tag {
    list-style: none;
    display: inline-block;
    margin: 5px;
    border: solid 1px $primaryColor;
    font-size: 11px;
    line-height: 20px;
    border-radius: 5px;
    padding: 0 15px;
    color: $primaryColor;

    @include respond-to('mobile') {
        font-size: 10px;
        line-height: 15px;
        padding: 0 8px;
    }
}

.tag--white,
%tag--white {
    border: solid 1px $white;
    color: $white;
}

.tag--purple,
%tag--purple {
    border: solid 1px $primaryColor;
    color: $primaryColor;
}
