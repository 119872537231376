.single-post {
    &__flex-components {
        > *:first-child {
            margin-top: 0;

            .header-subsection {
                border-top: 0;
            }

            .header-subsection__toggle {
                display: none;
            }
        }

        > *:nth-last-child(2) {
            margin-bottom: 0;
        }
    }

    &__share {
        &:first-child {
            display: none;
        }
    }

    .feature-share {
        max-width: 605px;
        margin: 30px auto 200px;
        display: flex;
        align-items: center;

        &__title {
            margin-right: 25px;
            margin-bottom: 0;
        }

        @include respond-to('tablet') {
            margin: 30px auto 120px;
            max-width: 509px;
            padding: 0 12px;
        }

        @include respond-to('mobile') {
            margin: 30px auto 80px;
        }

        @media (max-width: 400px) {
            display: block;

            &__title {
                margin-bottom: 10px;
            }
        }
    }
}
