.content-credits {
    margin: 100px 0;

    &__body {
        padding: 0 20px;

        a {
            color: $textColor;
            display: block;

            &:hover {
                text-decoration: none;
                color: $primaryColor;
            }
        }
    }

    &__grid {
        @include flex-grid(4, 47px);
    }

    &__group {
        margin-bottom: 27px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__title {
        @include font-style-paragraph-2;
        font-weight: 700;
        margin-bottom: 5px;
    }

    &__name {
        @include font-style-paragraph-3;

        &.no-link {
            pointer-events: none;
        }
    }

    @include respond-to('tablet') {
        margin: 60px 0;

        &__grid {
            @include flex-grid(2, 47px);
        }

        &__body {
            padding: 0 12px;
        }

        &__group {
            // margin-bototm:
        }
    }

    @include respond-to('mobile') {
        margin: 40px 0;

        &__grid {
            @include flex-grid(2, 20px);
        }

        &__group {
            margin-bottom: 20px;
        }
    }

    @media (max-width: 500px) {
        &__grid {
            flex-direction: column;
        }
    }
}
