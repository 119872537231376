.lightbox {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 101;
    opacity: 0;
    pointer-events: none;
    transition: all 200ms;
    display: flex;
    align-items: center;

    &.is-active {
        opacity: 1;
        pointer-events: all;
    }

    &__overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($white, 0.9);

        background-color: rgba($black, 0.9);

        [data-color='dark'] & {
            background-color: rgba($black, 0.9);
        }
    }

    &__container {
        width: 100%;
        max-height: calc(100% - 80px);
        display: flex;
    }

    &__swiper {
        max-height: 100%;
        max-width: calc(100% - 150px);
        width: 100%;

        @include respond-to('mobile') {
            max-width: calc(100% - 100px);
        }
    }

    .swiper-wrapper {
        align-items: center;
    }

    &__close {
        position: fixed;
        top: 20px;
        right: 20px;
        height: 25px;
        width: 25px;
        z-index: 10;
        cursor: pointer;

        span {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(45deg);
            width: 100%;
            height: 3px;
            background-color: $black;
            display: block;
            background-color: $white;
            border-radius: 2px;

            &:nth-child(2) {
                transform: translate(-50%, -50%) rotate(-45deg);
            }

            [data-color='dark'] & {
                background-color: $white;
            }
        }

        @include respond-to('mobile') {
            right: 10px;
            top: 10px;
        }
    }

    &__slide {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__image-wrapper {
        height: 100%;
    }

    &__image,
    &__video {
        max-height: 100%;
        max-width: 100%;
        height: auto;
        border-radius: 5px;
        overflow: hidden;
    }

    &__video {
        @include aspect-ratio(16, 9);
        position: relative;
        width: 100%;

        iframe {
            @include absolute-cover;
        }
    }

    &__image--has-credits {
        max-height: calc(100% - 42px);
    }

    &__image-credits {
        margin-top: 8px;
        color: $white;
    }

    &__next,
    &__prev {
        display: inline-block;
        width: 21px;
        height: 38px;
        background-image: url('../img/lightbox-prev.svg');
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        transform: translateX(50%);
        transition: all 200ms;
        position: fixed;
        top: 50%;
        cursor: pointer;

        @include respond-to('mobile') {
            height: 20px;
        }
    }

    &__prev {
        left: 13px;

        @include respond-to('mobile') {
            left: 0;
        }
    }

    &__next {
        right: 13px;
        transform: translateX(-50%);
        background-image: url('../img/lightbox-next.svg');

        @include respond-to('mobile') {
            right: 0;
        }
    }

    .project-video {
        position: relative;

        &__player {
            display: block;
            width: 100%;
            height: auto;
        }
    }
}
