.feature-share {
    &__title {
        @include font-style-paragraph-4;
        margin-bottom: 10px;
    }

    .svg__fill {
        fill: $black;
    }

    .social {
        margin-right: 24px;
        display: inline-block;

        &:last-child {
            margin-right: 0;
        }
    }
}

.social {
    &:hover {
        .svg__fill {
            fill: $primaryColor;
        }
    }
}
