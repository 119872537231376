.feature-gallery {
    margin: 105px 0;

    &__body {
        @include flex-grid(3, 15px);
        padding-top: 0;
    }

    &__column {
        &:first-child {
            flex: 0 0 100%;
        }
    }

    &__text {
        padding: 0 20px;
        max-width: 605px;
        margin-bottom: 60px;
    }

    &__thumbnail {
        @include aspect-ratio(399, 249);
        border-radius: 5px;
        position: relative;
        cursor: pointer;
        transition: $baseBazier;
        overflow: hidden;

        &:hover {
            border-radius: 5px 5px 5px 80px;
            // box-shadow: 0 4px 2px 0 rgba($black, 0.5);

            .feature-gallery__thumbnail-inner {
                transform: scale(1.1);
            }
        }
    }

    &__thumbnail-inner {
        @include background-cover;
        @include absolute-cover;
        transition: all 700ms cubic-bezier(0.19, 1, 0.22, 1);
    }

    &__thumbnail-credits {
        margin-top: 8px;

        // position: absolute;
        // bottom: -10px;
        // left: 0;
    }

    &__play {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
        width: 113px;
        height: 113px;
        background-color: $lightGray;
        border-radius: 5px;
        transition: $baseBazier;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
            border-radius: 5px 5px 40px;
        }
    }

    &__count {
        @include absolute-center;
        @include font-style-heading-3;

        color: rgba($white, 1);
        text-shadow: 0 0 2px $black;

        &--mobile {
            display: none;
        }
    }

    @include respond-to('tablet') {
        margin: 60px 0;

        &__body {
            @include flex-grid(3, 10px);
        }

        &__play {
            width: 83px;
            height: 83px;

            svg {
                width: 40px;
            }

            &:hover {
                border-radius: 5px 5px 30px;
            }
        }
    }

    @include respond-to('mobile') {
        margin: 40px 0;

        &__column {
            flex: 0 0 50%;

            &:last-child {
                display: none;
            }

            &:first-child {
                display: block;
            }
        }

        &__count {
            display: none;

            &--mobile {
                display: block;
            }
        }
    }
}
