.main-menu-active {
    overflow: hidden;
}

.main-menu {
    @include absolute-cover;
    position: fixed;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    background-color: $primaryColor;
    z-index: 90;
    transform: translateY(-100%);
    transition: $baseBazier;
    overflow-y: auto;

    // .main-menu-animated & {
    //     transition-delay: 500ms;
    // }

    &__inner {
        // @include absolute-cover;
        background-color: $lightGray;
        border-radius: 0;
        transition: $baseBazier;
        transition-delay: 500ms;

        .main-menu-animated & {
            transition-delay: 0ms;
        }
    }

    &__container {
        min-height: 100vh;
        min-height: 100vh;
        min-height: calc(var(--vh, 1vh) * 100);
        padding: 85px 0;
        display: flex;
        flex-direction: column;
    }

    &__logo {
        margin-bottom: 20px;

        .svg__fill {
            fill: $black;
        }
    }

    &__menu {
        margin: 0;
        margin-top: auto;
        padding: 0;
        padding-bottom: 18px;
        border-bottom: solid 1px $black;
    }

    &__menu-item {
        list-style: none;
        margin-bottom: 10px;
        // overflow: hidden;
        position: relative;
        transform: translateY(-10px);
        transition: $baseBazier;

        &::before {
            @include absolute-cover;
            height: 105%;
            content: '';
            display: block;
            background-color: $lightGray;
            transform: scaleY(1);
            transform-origin: bottom;

            transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
        }

        .main-menu-animated & {
            transform: translateY(0);

            &::before {
                transform: scaleY(0);
            }
        }

        // Staggered transition delay
        @for $x from 2 through 100 {
            &:nth-child(#{$x}) {
                transition-delay: 80ms * ($x - 1);

                &::before {
                    transition-delay: 80ms * ($x - 1);
                }
            }
        }
    }

    &__menu-link {
        @include font-style-heading-2;
        color: $black;

        &:hover {
            color: $primaryColor;
            text-decoration: none;
        }
    }

    .main-menu-active & {
        display: block;
        transform: translateY(0);

        .main-menu__inner {
            border-radius: 0 0 0 140px;
            // transition-delay: 0ms;
        }
    }

    &__footer {
        display: flex;
        padding: 35px 20px 0;
    }

    &__footer-menu {
        width: 25%;
        // flex-grow: 1;
        padding: 0;
        margin: 0;
    }

    &__footer-menu-item {
        list-style: none;
        margin-bottom: 18px;
        position: relative;
        transform: translateY(-10px);
        transition: $baseBazier;
        transition-delay: 500ms;

        .transition-reset & {
            transition-delay: 0ms !important;
            transition: all 0ms !important;
        }

        &::before {
            @include absolute-cover;
            height: 105%;
            content: '';
            display: block;
            background-color: $lightGray;
            transform: scaleY(1);
            transform-origin: bottom;

            transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
            transition-delay: 500ms;

            .transition-reset & {
                transition-delay: 0ms !important;
                transition: all 0ms !important;
            }
        }

        .main-menu-animated & {
            transform: translateY(0);

            &::before {
                transform: scaleY(0);
            }
        }

        // Staggered transition delay
        @for $x from 2 through 100 {
            &:nth-child(#{$x}) {
                transition-delay: 500ms + 80ms * ($x - 1);

                &::before {
                    transition-delay: 500ms + 80ms * ($x - 1);
                }
            }
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__footer-menu-link {
        @include font-style-heading-5;
        font-weight: 500;
        color: $black;

        &:hover {
            color: $primaryColor;
            text-decoration: none;
        }
    }

    &__socials {
        flex-grow: 1;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        opacity: 0;
        transition: all ease-in-out 300ms;
        // transition-delay: 900ms;

        .main-menu-animated & {
            opacity: 1;
        }

        .social {
            margin-right: 24px;
            display: inline-block;

            &:last-child {
                margin-right: 0;
            }
        }

        .svg__fill {
            fill: $black;
        }
    }

    &__socials-inner {
        width: 100%;
        text-align: right;
    }

    @include respond-to('tablet') {
        .main-menu-active & {
            .main-menu__inner {
                border-radius: 0 0 0 80px;
                // transition-delay: 0ms;
            }
        }

        &__container {
            padding-bottom: 55px;
        }

        &__footer {
            // display: block;
            flex-flow: row wrap;
        }

        &__footer-menu {
            width: 40%;
        }

        &__socials,
        &__socials-inner {
            width: 100%;
            text-align: left;
        }

        &__socials {
            margin-top: 63px;
        }
    }

    @include respond-to('mobile') {
        .main-menu-active & {
            .main-menu__inner {
                border-radius: 0 0 0 55px;
                // transition-delay: 0ms;
            }
        }

        &__container {
            padding-top: 55px;
            padding-bottom: 20px;
        }

        &__socials-inner {
            text-align: center;
        }

        &__footer {
            padding-left: 0;
            padding-right: 0;
        }
    }
}
