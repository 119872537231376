.teaser-faq {
    border-top: solid 1px $black;

    &:first-child {
        border-top: 0;
    }

    &__header {
        padding: 35px 20px 33px;
        display: flex;
        transition: $baseBazier;
        align-items: center;
    }

    &__body {
        @include font-style-paragraph-4;
        padding: 35px 20px;
        max-width: 567px;
        display: none;
    }

    &__title {
        @include font-style-heading-5;
        margin-right: 20px;
        cursor: pointer;
        transition: $baseDuration;

        &:hover {
            color: $primaryColor;
        }
    }

    &__toggle {
        position: relative;
        width: 24px;
        height: 24px;
        min-width: 24px;
        cursor: pointer;
        padding: 0;
        background: transparent;
        padding: 0;
        outline: none;
        box-shadow: none;
        border: 0;
        margin-left: auto;
        margin-bottom: 2px;

        &::after,
        &::before {
            content: '';
            display: block;
            position: absolute;
            width: 100%;
            height: 2px;
            background-color: $black;
            top: 50%;
            transition: all 100ms ease-in-out;
        }

        &::before {
            transform: rotate(90deg);

            .open & {
                transform: rotate(0deg);
            }
        }
    }

    .open & {
        &__header {
            background-color: $primaryColor;
            color: $white;
            border-radius: 0 0 0 40px;
        }

        &__title {
            color: $white;

            &:hover {
                color: $white;
            }
        }

        &__toggle {
            &::after,
            &::before {
                background-color: $white;
            }
        }
    }

    @include respond-to('tablet') {
        &__header {
            padding: 22px 12px 20px;
        }

        &__body {
            padding: 22px 12px 20px;
        }

        .open & {
            &__header {
                border-radius: 0 0 0 30px;
            }
        }
    }

    @include respond-to('mobile') {
        &__header {
            padding: 18px 12px 16px;
        }

        &__body {
            padding: 22px 12px 20px;
        }

        &__toggle {
            width: 15px;
            height: 15px;
            min-width: 15px;
        }

        .open & {
            &__header {
                border-radius: 0 0 0 20px;
            }
        }
    }
}
