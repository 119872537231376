.header-subsection {
    border-top: solid 1px $black;
    padding: 34px 20px;
    display: flex;
    align-items: center;

    &__title {
        @include font-style-heading-5;
    }

    &__date {
        @include font-style-paragraph-5;
        margin-bottom: 28px;
        display: block;
        font-style: italic;
    }

    &__toggle {
        position: relative;
        width: 24px;
        height: 24px;
        min-width: 24px;
        cursor: pointer;
        padding: 0;
        background: transparent;
        padding: 0;
        outline: none;
        box-shadow: none;
        border: 0;
        margin-left: auto;

        &::after,
        &::before {
            content: '';
            display: block;
            position: absolute;
            width: 100%;
            height: 2px;
            background-color: $black;
            top: 50%;
            transition: all 100ms ease-in-out;
        }

        &::before {
            transform: rotate(0);

            .closed & {
                transform: rotate(90deg);
            }
        }
    }

    @include respond-to('tablet') {
        padding: 22px 20px;
    }

    @include respond-to('mobile') {
        padding: 13px 12px;

        &__toggle {
            width: 15px;
            height: 15px;
            min-width: 15px;
        }
    }
}
