.teaser-agenda-list {
    border-bottom: solid 1px $black;

    &:first-child {
        border-top: solid 1px $black;
    }

    &__header {
        padding: 30px 20px;
        display: flex;
        transition: all 100ms ease-in-out;
        transform: translate3d(0, 0, 0);
        transition: $baseBazier;
        border-radius: 0 0 0 80px;
    }

    &__body {
        display: none;
    }

    &__column {
        width: 30%;
        flex-grow: 1;
        margin-right: 30px;
        transform: translate3d(0, 0, 0);
        transition: transform 0.5s cubic-bezier(0.19, 1, 0.22, 1);

        &:last-child {
            text-align: right;
            margin-right: 0;
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }
    }

    &__date {
        @include only-dutch {
            text-transform: lowercase;
        }
    }

    &__date-start {
        @include font-style-heading-5;
        display: block;

        span {
            @include font-style-heading-3;
            font-size: 50px;
        }
    }

    &__date-end {
        @include font-style-heading-5;
        font-weight: 400;
        display: block;

        strong {
            font-weight: 700;
        }
    }

    &__title {
        @include font-style-heading-3;
        margin-bottom: 15px;

        a {
            color: $textColor;
            font-weight: 700;
            transition: $baseTransition;

            &:hover {
                color: $primaryColor;
                text-decoration: none;
            }
        }

        &--mobile {
            display: none;
        }
    }

    &__readmore {
        @include button-style--readmore;
        display: inline-block;
        margin-top: 25px;
    }

    .tag {
        transition: all 100ms ease-in-out;
    }

    &__item {
        display: flex;
        align-items: flex-end;
        padding: 40px 20px;
        border-bottom: solid 1px rgba($black, 0.2);

        &:last-child {
            border-bottom: 0;
        }
    }

    &__item-column {
        width: 20%;
        flex-grow: 1;
        margin-right: 20px;

        &:last-child {
            text-align: right;
            margin-right: 0;
        }
    }

    &__item-date {
        @include font-style-heading-6;

        span {
            @include font-style-heading-5;
            display: block;
        }
    }

    &__item-location {
        @include font-style-heading-6;
    }

    &__item-city-time {
        @include font-style-heading-6;
        font-weight: 400;
    }

    &__show-data-close {
        display: none;
    }

    @include respond-to('mobile') {
        .teaser-agenda-list__header {
            flex-direction: column;
            padding: 20px 12px;
            border-radius: 0 0 0 50px;
        }

        .teaser-agenda-list__column {
            width: 100%;
            margin-right: 0;
            margin-bottom: 20px;
        }

        .teaser-agenda-list__title {
            display: none;

            &--mobile {
                display: block;
            }
        }

        .teaser-agenda-list__show-data {
            width: 100%;
        }
    }
}

.teaser-agenda-list.is-active {
    .teaser-agenda-list__header {
        background-color: $primaryColor;
        color: $white;
        // padding: 50px 20px 50px 40px;

        .teaser-agenda-list__show-data {
            @include button-style--white-no-border;
        }
    }

    .teaser-agenda-list__title {
        a {
            color: $white;

            &:hover {
                color: $white;
            }
        }
    }

    .teaser-agenda-list__readmore {
        @include button-style--readmore-white;
    }

    .teaser-agenda-list__column {
        &:nth-child(1),
        &:nth-child(2) {
            transform: translateX(20px);
        }
    }

    .tag {
        @extend %tag--white;
    }

    .teaser-agenda-simple__terms .tag {
        @extend %tag--purple;
    }

    .teaser-agenda-list__show-data-open {
        display: none;
    }

    .teaser-agenda-list__show-data-close {
        display: inline;
    }
}
