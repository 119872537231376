.styleguide-template {
    padding: 100px 0 400px;

    .paragraph-1 {
        @include font-style-paragraph-1;
    }

    .paragraph-2 {
        @include font-style-paragraph-2;
    }

    .paragraph-3 {
        @include font-style-paragraph-3;
    }

    .paragraph-4 {
        @include font-style-paragraph-4;
    }

    .paragraph-5 {
        @include font-style-paragraph-5;
    }
}

.background-placeholder {
    background-color: $primaryColor;
    padding: 20px;
    margin-top: 50px;
}
