.container {
    max-width: 1240px;
    margin-left: auto;
    margin-right: auto;

    @media (max-width: 1370px) {
        max-width: calc(100% - 120px);
    }

    @include respond-to('mobile') {
        max-width: calc(100% - 20px);
    }
}
