.novelty-cookie-popup {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    transition: $baseTransition;
    background-color: $primaryColor;
    max-width: 100vw;

    &.hidden {
        transform: translateY(110%);
    }

    &__container {
        padding: 20px 100px;
        width: 100%;
        position: relative;
        display: flex;
        align-items: center;
    }

    &__text {
        @include font-style-paragraph-4;
        color: $white;

        a {
            color: $white;
            text-decoration: underline;
        }
    }

    &__text,
    &__buttons {
        position: relative;
        z-index: 1;
        width: 50%;
    }

    &__buttons {
        text-align: right;
        margin-top: -10px;

        .button {
            margin-left: 40px;
            margin-top: 10px;
        }
    }

    @include respond-to('tablet') {
        &__container {
            display: block;
            padding: 20px 0;
        }

        &__text,
        &__buttons {
            width: 100%;
            text-align: center;
        }

        &__buttons {
            margin-top: 20px;

            .button {
                margin-left: 20px;
                margin-right: 20px;
            }
        }
    }
}
