.feature-carousel-programme {
    margin: 150px 0;
    overflow: hidden;

    &__carousel {
        position: relative;
    }

    &__swiper {
        overflow: visible;
    }

    &__prev,
    &__next {
        background-color: $white;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
        height: 85px;
        width: 85px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 35%;
        z-index: 2;
        cursor: pointer;

        &.swiper-button-disabled {
            display: none;
        }

        &::after {
            content: get-icon-chevron-right-large($black);
            display: block;
            width: 20px;
            height: 37px;
            margin-left: 4px;
        }
    }

    &__prev {
        left: -53px;

        &::after {
            transform: rotate(180deg);
        }
    }

    &__next {
        right: -53px;
    }

    .teaser-programme-grid {
        @include aspect-ratio(610, 346);
        width: 100%;
        max-width: 100%;
    }

    @include respond-to('tablet') {
        margin: 120px 0;

        &__prev,
        &__next {
            display: none;
        }
    }

    @include respond-to('mobile') {
        margin: 80px 0;

        &__carousel {
            padding-right: 40px;
        }
    }
}
