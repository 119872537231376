.relational-spotlight {
    margin: 70px 0;

    &__grid {
        display: flex;
    }

    &__swiper {
        display: none;
        max-width: calc(100% - 40px);
        margin-left: 0;
        overflow: visible;
    }

    &__title {
        @include font-style-heading-1;
        margin-bottom: 30px;
    }

    &__column {
        width: 100%;

        &:nth-child(1) {
            display: flex;
            margin-right: 20px;
        }
    }

    @include respond-to('tablet') {
        margin: 60px 0;
    }

    @include respond-to('mobile') {
        margin: 40px 0;
        overflow-x: hidden;

        &__grid {
            display: none;
        }

        &__swiper {
            display: block;
        }
    }
}

.relational-spotlight-item {
    @include aspect-ratio(610, 346);
    color: $white;
    width: 100%;
    position: relative;
    border-radius: 5px;
    margin-bottom: 20px;
    overflow: hidden;
    transition: $baseBazier;

    &::before {
        @include absolute-cover;
        content: '';
        display: block;
        background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0.456) 18.1%,
            rgba(0, 0, 0, 0) 99.03%
        );
        height: 178px;
        // transform: rotate(180dg);
        z-index: 1;
    }

    &__image {
        @include background-cover;
        @include absolute-cover;
        transition: all 700ms cubic-bezier(0.19, 1, 0.22, 1);
    }

    &__content {
        @include absolute-cover;
        z-index: 2;
        padding: 13px 18px 18px;
    }

    &__label {
        @include font-style-paragraph-1;
        position: absolute;
        top: 10px;
        left: 18px;
        margin: 0;
        transform: translateY(-40px);
        transition: $baseBazier;
        opacity: 0;
    }

    &__tag {
        position: absolute;
        top: 18px;
        right: 18px;
        margin: 0;
        z-index: 2;
    }

    &__title {
        @include font-style-heading-3;
        font-size: 50px;
        padding-right: 85px;
        transition: $baseBazier;
    }

    &__term {
        @include font-style-paragraph-2;
        margin-top: 4px;
        transition: $baseBazier;
    }

    &__link {
        @include absolute-cover;
        z-index: 2;
    }

    &:hover {
        border-radius: 5px 5px 5px 120px;
        // box-shadow: 0 4px 2px 0 rgba($black, 0.5);

        .relational-spotlight-item__image {
            transform: scale(1.1);
        }

        .relational-spotlight-item__label {
            transform: translateY(0);
            opacity: 1;
        }

        .relational-spotlight-item__title,
        .relational-spotlight-item__term {
            transform: translateY(30px);
        }
    }

    @include respond-to('tablet') {
        &:hover {
            border-radius: 5px 5px 5px 80px;
        }
    }

    @include respond-to('mobile') {
        @include aspect-ratio(270, 363);

        &:hover {
            border-radius: 5px 5px 5px 55px;
        }
    }
}

.relational-spotlight-item--medium {
    @include aspect-ratio(610, 668);
    margin-bottom: 0;
}

.relational-spotlight-item--large {
    // min-height: 668px;
    margin-bottom: 0;
    height: auto;

    &::before {
        @include absolute-cover;
        content: '';
        display: block;
        background: none;
        background-color: rgba($black, 0.3);
        height: 100%;
    }
}
