//
// Buttons
// --------------------------------------------------
@mixin button-reset {
    display: inline-block;
    background-color: transparent;
    border: 0;
    outline: none;
    box-shadow: none;
    width: auto;
}

@mixin button-style {
    @include button-reset;
    border-radius: 5px;
    font-size: 14px;
    line-height: 1.714285714;
    font-weight: 500;
    border: solid 1px $primaryColor;
    padding: 7px 20px;
    background-color: $primaryColor;
    color: $white;
    transition: $baseBazier;
    // box-shadow: 0 2px 2px 0 rgba($black, 0);
    display: inline-block;
    min-width: 180px;
    text-align: center;

    // &:focus {
    //     border: 0;
    //     outline: 0;
    // }

    &:hover {
        background-color: $primaryColor;
        border: solid 1px $primaryColor;
        color: $white;
        text-decoration: none;
        border-radius: 40px;
        // box-shadow: 0 2px 2px 0 rgba($black, 0.8);
    }

    &.is-hidden {
        display: none;
    }
}

@mixin button-style--filter {
    border: solid 1px $primaryColor;
    background-color: $white;
    color: $primaryColor;
    min-width: 0;

    &:hover,
    &.checked {
        background-color: $primaryColor;
        border: solid 1px $primaryColor;
        color: $white;
    }
}

@mixin button-style--radio {
    border: solid 1px $black;
    background-color: $white;
    color: $black;
    min-width: 96px;

    &:hover {
        background-color: $primaryColor;
        border: solid 1px $primaryColor;
        color: $white;
    }
}

@mixin button-style--white {
    border: solid 1px $black;
    background-color: $white;
    color: $black;

    &:hover {
        background-color: $white;
        border: solid 1px $black;
        color: $black;
    }
}

@mixin button-style--white-no-border {
    border: solid 1px $white;
    background-color: $white;
    color: $black;

    &:hover {
        background-color: $white;
        border: solid 1px $white;
        color: $black;
    }
}

@mixin button-style--disabled {
    border: solid 1px $black;
    background-color: $white;
    color: $black;
    opacity: 0.2;
    pointer-events: none;

    &:hover {
        background-color: $white;
        border: solid 1px $black;
        color: $black;
    }
}

@mixin button-style--ghost {
    border: solid 1px $white;
    background-color: transparent;
    color: $white;

    &:hover {
        background-color: $white;
        border: solid 1px $black;
        color: $black;
        // border-radius: 5px;
    }
}

@mixin button-style--full-white {
    border: solid 1px $white;
    background-color: $white;
    color: $black;
    opacity: 0.7;

    &:hover {
        background-color: $white;
        border: solid 1px $black;
        color: $black;
        border-radius: 5px;
        opacity: 1;
    }
}

@mixin button-style--readmore {
    color: $black;
    transition: all 100ms ease-in-out;
    display: inline-block;

    &::after {
        display: inline-block;
        content: get-icon-arrow-right($black);
        width: 12px;
        height: 10px;
        display: inline-block;
        margin-left: 5px;
        transition: all 100ms ease;
    }

    &:hover {
        text-decoration: none;

        &::after {
            transform: translateX(5px);
        }
    }
}

@mixin button-style--readmore-white {
    color: $white;

    &::after {
        display: inline-block;
        content: get-icon-arrow-right($white);
        width: 12px;
        height: 10px;
        display: inline-block;
        margin-left: 5px;
        transition: all 100ms ease;
    }

    &:hover {
        text-decoration: none;

        &::after {
            transform: translateX(5px);
        }
    }
}

@mixin button-style--readmore-purple {
    color: $primaryColor;

    &::after {
        content: get-icon-arrow-right($primaryColor);
    }
}

.button {
    @include button-style;
}

.button--white {
    @include button-style--white;
}

.button--filter {
    @include button-style--filter;
}

.button--disabled {
    @include button-style--disabled;
}

.button--ghost {
    @include button-style--ghost;
}

.button--full-white {
    @include button-style--full-white;
}

.button .loading-bar {
    transform: translate(10px, -50%);
    opacity: 0;
    transition: all 0.3s ease;
}

.button--is-loading {
    position: relative;

    text-indent: -20px;

    .loading-bar {
        opacity: 1;
        position: absolute;
        top: 50%;
        right: 30px;
        transform: translateY(-50%);
    }

    .loading-bar::after {
        content: '';
        border: 2px solid $white;
        border-right-color: rgba($white, 0.4);
        border-radius: 100%;
        width: 12px;
        height: 12px;
        display: block;

        animation: spin 0.9s linear 0s infinite;
        animation-fill-mode: forwards;
    }
}
