.faq {
    margin-bottom: 44px;

    &__items {
        padding-top: 44px;
        padding-left: 20px;
    }

    &__item + &__item {
        margin-top: 42px;
    }

    &__question {
        @include font-style-paragraph-1;
        list-style-type: none;
        display: flex;
        align-items: center;
        line-height: 1;
    }

    &__question-icon {
        position: relative;
        width: 24px;
        height: 24px;
        display: inline-block;
        margin-right: 12px;

        &::before {
            content: '';
            content: '';
            display: block;
            position: absolute;
            width: 100%;
            height: 2px;
            background-color: #000;
            top: 50%;
            transition: all 100ms ease-in-out;
            transform: translateY(-50%);
        }

        &::after {
            content: '';
            content: '';
            display: block;
            position: absolute;
            width: 100%;
            height: 2px;
            background-color: #000;
            top: 50%;
            transition: all 100ms ease-in-out;
            transform: translateY(-50%) rotate(90deg);
        }
    }

    &__content {
        @include font-style-paragraph-2;

        font-size: 14px;
        margin-top: 18px;
    }

    &__item[open] {
        .faq__question-icon::after {
            transform: translateY(-50%) rotate(0);
        }
    }
}
