//
// Forms
// --------------------------------------------------
/* stylelint-disable */

// Invisible recaptcha
.inv-recaptcha-holder {
    display: none !important;
}

// Basic Styles
// -------------------

form {
    display: block;
}

fieldset {
    display: block;
    margin: 1.25em 0;
    padding: 0;
}

legend {
    display: block;
    width: 100%;
    margin: 0 0 1em;
    font-weight: bold;
    border-bottom: 1px solid $baseColor;
}

label {
    display: block;
    margin: 0 0 0.25em;
    font-weight: bold;
}

// Form Elements
// -------------------
form {
    margin: 0;
}

textarea,
input[type='text'],
input[type='password'],
input[type='datetime'],
input[type='datetime-local'],
input[type='date'],
input[type='month'],
input[type='time'],
input[type='week'],
input[type='number'],
input[type='email'],
input[type='url'],
input[type='search'],
input[type='tel'],
input[type='image'],
input[type='color'] {
    display: block;
    padding: 0.5em;
    margin: 0 0 0.625em;
    vertical-align: middle;
    border: 1px solid $baseColor;
    outline: none;
    font-family: inherit;
    font-size: 1rem;
    border-radius: 0;
    appearance: none;
    appearance: none;

    &:hover {
        border: 1px solid darken($baseColor, 3%);
    }

    &:focus {
        border: 1px solid darken($baseColor, 5%);
    }
}

// Form Buttons

input[type='submit'],
input[type='button'] {
    // display: block;
    // border-radius: 0;
}

input[type='file'] {
    padding: 0.125em;
    margin: 0 0 0.625em;
    font-family: inherit;
    font-size: 1rem;
    appearance: none;
    border-radius: 0;
    line-height: 100%;
}

// Misc Additions

textarea {
    padding: 0.25em; // to make the textarea more writeable
}

// Bonus HTML5 Elements

progress,
meter {
    padding: 0.125em;
    margin: 0 0 0.625em;
    font-family: inherit;
    font-size: 1rem;
}

input[type='text'],
input[type='email'],
input[type='number'],
textarea {
    @include font-style-paragraph-3;
    width: 100%;
    margin: 0;
    padding: 7px 20px 5px;
    background-color: $gray;
    border-radius: 5px;
    border: solid 1px $gray;
    text-align: left;

    &::placeholder,
    &::-moz-placeholder,
    &::-webkit-input-placeholder {
        @include font-style-paragraph-3;
    }

    &:hover {
        border: solid 1px $gray;
    }

    &:focus {
        border-color: $primaryColor;
        color: $primaryColor;

        &::placeholder,
        &::-moz-placeholder,
        &::-webkit-input-placeholder {
            color: $primaryColor;
        }
    }

    .gfield_error &,
    &.error {
        border-color: $errorColor;

        &:focus {
            border-color: $errorColor;
            color: $errorColor;

            &::placeholder,
            &::-moz-placeholder,
            &::-webkit-input-placeholder {
                color: $errorColor;
            }
        }
    }
}

input[type='number'] {
    text-align: left;
}

input[type='submit'],
button[type='submit'] {
    @media (max-width: 500px) {
        width: 100%;
    }
}

// input[type='number'] {
//     /*for absolutely positioning spinners*/
//     position: relative;
//     padding: 5px;
//     padding-right: 25px;
//     text-align: center;
// }

// input[type='number']::-webkit-inner-spin-button,
// input[type='number']::-webkit-outer-spin-button {
//     opacity: 1;
// }

// input[type='number']::-webkit-outer-spin-button,
// input[type='number']::-webkit-inner-spin-button {
//     -webkit-appearance: none !important;
//     width: 40px;
//     height: 40px;
//     position: absolute;
//     top: 0;
//     height: 100%;
//     background-color: transparent;
//     background-size: contain;
//     background-repeat: no-repeat;
//     background-position: center;
// }

// input[type='number']::-webkit-outer-spin-button {
//     // left: 10px;
//     // background-image: url('../img/icon-minus.svg');
//     // background-color: red;
// }

// input[type='number']::-webkit-inner-spin-button {
//     left: -2000px;
//     top: -2000px;
//     width: 4000px;
//     height: 4000px;
//     // background-image: url('../img/icon-plus.svg');
//     background-color: rgba(red, 0.5);
//     transform: rotate(90deg);
// }

.gform_title.gform_title {
    @include font-style-heading-4;
    margin-bottom: 0.8em;
}

textarea {
    height: 222px;
}

.rsvp-code-form,
.gform_fields,
.mollie-payment-form {
    display: flex;
    flex-flow: row wrap;
    margin-left: -10px;
    margin-right: -10px;
}

.gfield,
.mollie-payment-form__field-group,
.rsvp-code-form__field-group {
    margin-bottom: 23px;
    width: 100%;
    flex-grow: 1;
    margin-left: 10px;
    margin-right: 10px;

    &--width-half {
        width: 40%;
        max-width: calc(50% - 20px);
    }

    &.hidden_label .gfield_label {
        display: none;
    }

    &.open-select {
        z-index: 100;
    }

    @include respond-to('tablet') {
        margin-bottom: 20px;
    }

    @include respond-to('mobile') {
        &--width-half {
            width: 100%;
            max-width: 100%;
        }
    }
}

.gfield_html {
    margin-bottom: 33px;
}

.gfield.spacer {
    display: none;
}

.gfield_label,
.gfield_description,
.mollie-payment-form__label,
.rsvp-code-form__label {
    @include font-style-paragraph-4;
    padding: 0 21px;
    border: 0;

    .gfield_error & {
        color: $errorColor;
    }
}

.gfield_description {
    @include font-style-paragraph-5;
    margin-top: -12px;
    margin-bottom: 16px;
}

.gfield_consent_label,
.gchoice label {
    @include font-style-paragraph-4;
    display: inline-flex;
    align-items: center;
    cursor: pointer;

    a {
        margin-left: 4px;
        line-height: inherit;
        font-size: inherit;
    }

    &:before {
        content: '';
        display: inline-block;
        height: 25px;
        width: 25px;
        min-width: 25px;
        border-radius: 50%;
        background-color: $white;
        border: solid 1px $black;
        margin-top: -3px;
        margin-right: 13px;
        box-shadow: inset 0 0 0 3px $white;
    }

    @include respond-to('tablet') {
        &:before {
            height: 18px;
            width: 18px;
            min-width: 18px;
            margin-top: -1px;
        }
    }

    @include respond-to('mobile') {
        &:before {
            height: 12px;
            width: 12px;
            min-width: 12px;
            margin-top: 0px;
        }
    }
}

.consent-field,
.radio-field {
    margin-top: 40px;
    position: relative;

    &.consent-margin-top {
        margin-top: 10px;
    }

    .gfield_required_asterisk {
        display: none;
    }

    a {
        margin-left: 5px;
    }

    .gfield_label {
        display: none;
    }

    .validation_message {
        display: block;
        position: absolute;
        left: 38px;
        top: 0;
        padding-bottom: 5px;
        transform: translateY(-100%);
    }

    @include respond-to('tablet') {
        margin-top: 20px;
    }
}

.validation_message,
.gform_submission_error {
    @include font-style-paragraph-4;
    color: $errorColor;
    display: none;
}

.ginput_container_consent,
.gchoice {
    input[type='checkbox'],
    input[type='radio'] {
        display: none;

        &:checked ~ {
            .gfield_consent_label:before,
            label:before {
                background-color: $primaryColor;
            }
        }
    }
}

.gfield_radio {
    display: flex;
    flex-flow: row wrap;
    margin-left: -10px;
    margin-right: -10px;
}

.gchoice {
    width: 40%;
    flex-grow: 1;
    margin: 0 10px;

    @include respond-to('mobile') {
        width: 100%;
    }
}

// .gfield_radio {
//     margin-left: -10px;
//     margin-top: -10px;
//     margin-bottom: -10px;
// }

// .gchoice {
//     display: inline-block;
//     margin: 10px;

//     input[type='radio'] {
//         display: none;

//         &:checked ~ {
//             label {
//                 background-color: $primaryColor;
//                 border: solid 1px $primaryColor;
//                 color: $white;
//                 text-decoration: none;
//                 border-radius: 40px;
//                 box-shadow: 0 2px 2px 0 rgba($black, 0.8);
//             }
//         }
//     }

//     label {
//         @include button-style;
//         @include button-style--radio;
//         margin: 0;
//         cursor: pointer;
//     }
// }

.goud-glans-donate {
    .button,
    .button:hover {
        font-size: 18px;
    }
}
