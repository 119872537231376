.governance-template {
    margin: 160px 0 0;

    &__wysiwyg {
        &.closed {
            .content-wysiwyg {
                margin-bottom: 0;
            }
        }
    }

    .content-wysiwyg {
        .header-subsection {
            border-top: 0;
        }

        .header-subsection__toggle {
            display: none;
        }
    }

    @include respond-to('tablet') {
        margin: 95px 0 0;
    }

    @include respond-to('mobile') {
        margin: 75px 0 0;
    }
}
