.single-product {
    padding-top: 90px;

    .wps-product-individual-price {
        transform: translateX(0) !important;
        opacity: 1 !important;
    }

    .wps-product-image-on-sale-label {
        display: none;
    }

    .wps-product-single-content {
        .wps-component-products-title .wps-products-title {
            @include font-style-heading-2;
        }

        .wps-product-individual-price {
            @include font-style-heading-3;
        }

        .wps-component-products-description {
            @include font-style-paragraph-3;
        }
    }

    .single-product-button-container {
        .wps-component-products-add-button button {
            @include button-style;
            margin-top: 25px;
        }

        .wps-btn-dropdown {
            .wps-icon-dropdown {
                background-color: transparent;
                color: $black;
                text-align: left;
                border: 1px solid $black;
                font-size: 14px;
                line-height: 1.714285714;
                padding: 8px 40px 6px 20px;

                svg path {
                    fill: $black;
                }
            }

            [aria-expanded='true'] .wps-icon-dropdown {
                border: 1px solid $actionColor;
            }

            .tippy-box {
                border: 1px solid $actionColor;
            }

            .wps-product-variant {
                text-align: left;
                padding: 7px 20px;
                font-size: 14px;
                line-height: 1.4;
            }
        }
    }

    @include respond-to('mobile') {
        padding-top: 30px;
    }
}
