.content-wysiwyg {
    margin: 100px 0;

    .template-page & {
        margin: 85px 0;
    }

    &__text {
        max-width: 605px;
        margin-left: auto;
        margin-right: auto;
        padding-top: 41px;

        p,
        li {
            @include font-style-paragraph-2;
        }

        > div:not(.goud-glans-donate_wrapper) {
            max-width: calc(100% + 120px);
            margin-left: -60px;
        }

        .wp-caption-text {
            @include font-style-paragraph-5;
            margin-top: 8px;
        }
    }

    @include respond-to('tablet') {
        margin: 60px 0;

        .template-page & {
            margin: 60px 0;
        }

        &__text {
            max-width: 509px;
            padding: 20px 12px 0;
        }
    }

    @include respond-to('mobile') {
        margin: 40px 0;

        .template-page & {
            margin: 40px 0;
        }

        &__text {
            p,
            li {
                @include font-style-paragraph-4;
            }

            > div:not(.goud-glans-donate_wrapper) {
                max-width: calc(100% + 20px);
                margin-left: -10px;
            }
        }
    }
}

.content-wysiwyg--alignment-left {
    .content-wysiwyg__text {
        margin-left: 20px;

        > div:not(.goud-glans-donate_wrapper) {
            max-width: 100%;
            margin-left: 0;
        }
    }
}

.content-wysiwyg--alignment-right {
    .content-wysiwyg__text {
        margin-right: 20px;

        > div:not(.goud-glans-donate_wrapper) {
            max-width: 100%;
            margin-left: 0;
        }
    }
}
