.content-embed {
    margin: 100px 0;

    .template-page & {
        margin: 85px 0;
    }

    &__text {
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
        padding-top: 41px;

        p,
        li {
            @include font-style-paragraph-2;
        }

        > div:not(.goud-glans-donate_wrapper) {
            max-width: calc(100% + 120px);
            margin-left: -60px;
        }

        .wp-caption-text {
            @include font-style-paragraph-5;
            margin-top: 8px;
        }
    }

    &__grid {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
        gap: 32px;

        .shopify-buy-frame {
            max-width: min(100%, 500px) !important;
            margin-inline: auto;
            width: 100%;
        }
    }

    @include respond-to('tablet') {
        margin: 60px 0;

        .template-page & {
            margin: 60px 0;
        }

        &__text {
            // max-width: 509px;
            padding: 20px 12px 0;
        }
    }

    @include respond-to('mobile') {
        margin: 40px 0;

        .template-page & {
            margin: 40px 0;
        }

        &__text {
            p,
            li {
                @include font-style-paragraph-4;
            }

            > div:not(.goud-glans-donate_wrapper) {
                max-width: calc(100% + 20px);
                margin-left: -10px;
            }
        }
    }
}

.content-embed--alignment-left {
    .content-embed__text {
        margin-left: 20px;

        > div:not(.goud-glans-donate_wrapper) {
            max-width: 100%;
            margin-left: 0;
        }
    }
}

.content-embed--alignment-right {
    .content-embed__text {
        margin-right: 20px;

        > div:not(.goud-glans-donate_wrapper) {
            max-width: 100%;
            margin-left: 0;
        }
    }
}
