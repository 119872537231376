//
// Global Settigns
// --------------------------------------------------

* {
    box-sizing: border-box;
}

html.admin-bar {
    margin-top: 0 !important;
    padding-top: 32px !important;

    @media screen and (max-width: 782px) {
        html {
            margin-top: 0 !important;
            padding-top: 46px !important;
        }
    }
}

.error {
    color: $errorColor;
}

.success {
    color: $successColor;
}

img {
    image-rendering: optimize-contrast;
    max-width: 100%;
    height: auto;
    border-radius: 5px;
}

svg {
    max-width: 100%;
    height: auto;
}

main {
    display: block;

    &.template-page.additional-spacing {
        padding-top: 160px;
    }

    @include respond-to('mobile') {
        &.template-page.additional-spacing {
            padding-top: 40px;
        }
    }
}

body {
    background-color: $lightGray;
}

.date {
    @include only-dutch {
        text-transform: lowercase;
    }
}
