.site-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    box-shadow: 0 4px 4px 0 rgba($black, 0);
    border-radius: 0 0 0 40px;
    transition: all 150ms ease;
    z-index: 101;

    &__container {
        display: flex;
        align-items: center;
        padding: 13px 0 11px;
        position: relative;
    }

    &__logo {
        max-width: 300px;
        display: block;
        margin-top: 5px;
        margin-right: 20px;
        transition: all 150ms ease;

        .search-active & {
            opacity: 0 !important;
        }

        .svg__fill {
            fill: $white;
        }
    }

    &__menu {
        margin: 0;
        padding: 0;
        margin-left: auto;
    }

    &__menu-item {
        list-style: none;
        display: inline-block;
        margin-right: 30px;

        &:last-child {
            margin-right: 60px;
        }
    }

    &__menu-link {
        @include font-style-paragraph-3;
        color: $white;
        transition: all 150ms ease;
        font-weight: 500;

        .search-active & {
            opacity: 0;
        }

        &:hover {
            color: $primaryColor;
            text-decoration: none;
        }
    }

    &__search {
        position: absolute;
        left: 0;
        width: calc(100% - 166px);
        transition: $baseBazier;
        opacity: 0;
        pointer-events: none;
        display: flex;
        z-index: 2;

        form {
            width: 100%;
        }

        input[type='submit'] {
            position: absolute;
            top: 0;
            left: 0;
            border: 0;
            background-color: transparent;
            opacity: 0.5;
            padding: 0;
            width: 25px;
            height: 100%;
            z-index: 1;
        }

        .search-active & {
            opacity: 1;
            pointer-events: all;
        }
    }

    &__search-icon {
        position: absolute;
        top: 8px;
        left: 0;

        .svg__fill {
            fill: $white;
        }
    }

    input[type='text'].site-header__search-input {
        background-color: transparent;
        border-radius: 0;
        border: 0;
        border-bottom: solid 1px $white;
        color: $white;
        font-weight: 500;
        transform: scaleX(0);
        transform-origin: right;
        transition: $baseBazier;
        padding-left: 40px;

        &::placeholder {
            color: $white;
        }
    }

    &.search-active {
        input[type='text'].site-header__search-input {
            transform: scaleX(1);
        }
    }

    &__buttons {
        display: flex;
        align-items: center;
    }

    &__button {
        @include font-style-paragraph-3;
        height: 40px;
        width: 40px;
        min-width: 40px;
        background-color: rgba($mediumGray, 0.3);
        border-radius: 5px;
        display: inline-flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        color: $white;
        font-weight: 500;
        margin-right: 2px;
        transition: all 150ms ease;
        outline: none;
        border: 0;

        &.disabled {
            pointer-events: none;
        }

        .svg__fill {
            fill: $white;
        }

        &--language-switch {
            width: 116px;

            .search-active & {
                opacity: 0;
            }
        }

        &--menu {
            width: 80px;
        }

        &--search-close {
            margin-left: 15px;
        }

        &--search-open {
            .search-active & {
                opacity: 0;
            }
        }

        &--accessibility {
            position: relative !important;
            top: 0 !important;
            left: 0 !important;
            float: none !important;
            margin: 0 !important;
            margin-right: 2px !important;
            padding: 0 !important;
            height: 40px !important;
            background-color: rgba($mediumGray, 0.3) !important;
            width: 40px !important;

            svg {
                width: auto !important;
                height: auto !important;

                path:first-child,
                path:last-child {
                    fill: #fff !important;
                }

                path:last-child {
                    stroke: transparent !important;
                }
            }

            &:hover {
                text-decoration: none !important;
                background-color: $white !important;
                color: $black !important;

                .svg__fill {
                    fill: $black !important;
                }
            }
        }

        &:hover {
            text-decoration: none;
            background-color: $white;
            color: $black;

            .svg__fill {
                fill: $black;
            }

            .language-switch__language.current {
                color: $black;
            }

            .language-switch__language {
                &:hover {
                    color: $black;
                }
            }

            .language-switch__seperator {
                background-color: $black;
            }
        }
    }

    &__button-inner-open {
        .main-menu-active & {
            display: none;
        }
    }

    &__button-inner-close {
        display: none;
        width: 19px;
        height: 19px;

        .main-menu-active & {
            display: inline-block;
        }
    }

    @media (max-width: 1380px) {
        &__menu-item {
            margin-right: 16px;

            &:last-child {
                margin-right: 16px;
            }
        }
    }

    @media (max-width: 1240px) {
        &__menu-item {
            margin-right: 8px;

            &:last-child {
                margin-right: 8px;
            }
        }
    }

    @media (max-width: 1160px) {
        &__menu {
            display: none;
        }

        &__buttons {
            margin-left: auto;
        }
    }

    // @include respond-to('tablet') {
    //     border-radius: 0 0 0 30px;
    // }

    @include respond-to('mobile') {
        border-radius: 0 0 0 20px;

        &__logo {
            max-width: 130px;
        }

        &__search {
            width: 100%;
        }

        input[type='text'].site-header__search-input {
            padding: 5px 20px 3px 30px;
        }

        &__search-icon {
            top: 8px;
            max-width: 17px;
        }

        &__button {
            @include font-style-paragraph-5;
            font-weight: 500;
            height: 30px;
            width: 30px;
            min-width: 30px;

            .svg {
                max-width: 17px;
            }

            &--language-switch {
                display: none;
            }

            &--menu {
                width: 50px;
            }

            .search-active & {
                opacity: 0;
            }

            &--accessibility {
                font-weight: 500;
                height: 30px !important;
                width: 30px !important;
                min-width: 30px !important;
            }

            &--search-close {
                margin-left: 10px;

                .search-active & {
                    opacity: 1;
                }
            }
        }
    }
}

@mixin site-header-dark {
    background-color: $white;
    box-shadow: 0 2px 2px 0 rgba($black, 0.1);

    .svg__fill {
        fill: $black;
    }

    .site-header__menu-link {
        color: $black;

        &:hover {
            color: $primaryColor;
        }
    }

    .site-header__button {
        background-color: rgba($black, 0.6);

        &--accessibility {
            background-color: rgba($black, 0.6) !important;
        }

        .svg__fill {
            fill: $white;
        }

        &:hover {
            .svg__fill {
                fill: $black;
            }
        }
    }

    .language-switch__language.current {
        color: $white;
    }

    .site-header__button {
        &:hover {
            background-color: rgba(#868686, 0.6);

            .language-switch__language {
                color: $black;
            }

            .language-switch__language.current {
                color: $white;
            }
        }

        &--accessibility:hover {
            background-color: rgba(#868686, 0.6) !important;
        }

        .language-switch__language:hover {
            color: $white;
        }
    }

    input[type='text'].site-header__search-input {
        background-color: transparent;
        border-radius: 0;
        border: 0;
        border-bottom: solid 1px $black;
        color: $black;
        font-weight: 500;

        &::placeholder {
            color: $black;
        }
    }
}

.site-header.scrolled {
    @include site-header-dark;
}

.main-menu-active {
    .site-header {
        @include site-header-dark;
        background-color: $lightGray;
        box-shadow: none;
    }

    .site-header__logo {
        opacity: 0 !important;
    }
}

body:not(.home):not(.single-programme):not(.page-template-default):not(
        .page-template-get-involved-template
    ) {
    .site-header--dark {
        @include site-header-dark;
        background-color: transparent;
        box-shadow: none;

        &.scrolled {
            @include site-header-dark;
        }
    }

    &.main-menu-active {
        .site-header {
            @include site-header-dark;
            background-color: $lightGray;
            box-shadow: none;
        }
    }
}

body.home {
    .site-header__logo {
        opacity: 0;
    }

    .site-header.scrolled {
        .site-header__logo {
            opacity: 1;
        }
    }
}
