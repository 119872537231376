.content-text-and-images {
    margin-bottom: 100px;

    .header-subsection {
        align-items: flex-start;

        &__title {
            max-width: 50%;
            padding-right: 70px;
        }
    }

    &__body {
        padding: 0 20px;
    }

    &__grid {
        @include flex-grid(2, 2px);
    }

    &__column {
        &:nth-child(1) {
            padding-right: 110px;
        }

        &:nth-child(2) {
            // padding-top: 59px;
        }
    }

    &__title {
        @include font-style-heading-5;
        margin-bottom: 10px;
    }

    &__date {
        @include font-style-paragraph-5;
        margin-bottom: 28px;
        display: block;
        font-style: italic;
    }

    &__intro {
        @include font-style-paragraph-3;
        margin-bottom: 25px;
    }

    &__subtitle {
        @include font-style-heading-6;
        margin-bottom: 5px;
    }

    &__text {
        p {
            @include font-style-paragraph-2;
        }

        iframe {
            width: 1200px !important;
            aspect-ratio: var(--width) / var(--height);
            height: auto !important;
        }
    }

    &__image {
        margin-bottom: 33px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__image-caption {
        @include font-style-paragraph-5;
        margin-top: 5px;
    }

    &:nth-child(1) {
        .content-text-and-images__body {
            padding-top: 40px;
        }
    }

    @include respond-to('tablet') {
        margin-bottom: 60px;

        .header-subsection {
            align-items: flex-start;

            &__title {
                max-width: 485px;
                padding-right: 0;
            }
        }

        &__body {
            padding: 0 12px;
        }

        &:nth-child(1) {
            .content-text-and-images__body {
                padding-top: 28px;
            }
        }

        &__grid {
            flex-direction: column;
        }

        &__column {
            &:nth-child(1) {
                padding-right: 0;
                margin-bottom: 40px;
            }
        }

        &__text {
            max-width: 485px;
            margin-left: auto;
            margin-right: auto;
        }
    }

    @include respond-to('mobile') {
        margin-bottom: 40px;

        &__body {
            padding-top: 19px;
        }

        &:nth-child(1) {
            .content-text-and-images__body {
                padding-top: 19px;
            }
        }

        &__text {
            p {
                @include font-style-paragraph-4;
            }
        }
    }
}
