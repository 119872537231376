.post-password-form {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 5%;
    max-width: 500px;
    margin: 0 auto;

    p {
        width: 100%;
        font-size: rem(16px);
        margin-bottom: em(16px);
        max-width: rem(500px);
        margin-bottom: 1em;
    }

    p:last-child {
        display: flex;
        flex-direction: column;
    }

    input[type='password'] {
        width: 100%;
        display: block;
        border: 1px solid $black;
        padding: em(8px) em(16px);
        margin: em(16px) 0;
    }

    input[type='submit'] {
        background-color: $black;
        display: inline-block;
        max-width: max-content;
        padding: em(8px) em(16px);
        color: $white;
    }
}
