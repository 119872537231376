.single-programme {
    .header-basic__buttons {
        display: none;
    }

    &__container {
        margin-bottom: 110px;
    }

    &__title {
        margin-bottom: 27px;
    }

    &__agenda-title {
        @include font-style-heading-5;
        margin-bottom: 30px;
        margin-top: 34px;
        padding: 0 20px;
    }

    .teaser-agenda-simple {
        display: none;
    }

    &__agenda-buttons {
        padding-top: 100px;
        text-align: center;
    }

    &__agenda-button {
        @include button-style--white;
    }

    @include respond-to('tablet') {
        &__title {
            margin-bottom: 17px;
        }

        &__agenda-title {
            padding: 0 12px;
            margin-top: 22px;
        }

        &__agenda-buttons {
            margin-bottom: 80px;
            padding-top: 60px;
        }
    }

    @include respond-to('tablet') {
        // margin-top: 13px;
    }
}
