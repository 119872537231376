.ginput_container_number {
    position: relative;

    .instruction {
        display: none;
    }
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    appearance: none;
    margin: 0;
}

input[type='number'] {
    appearance: textfield;
    text-align: left;
}

.ginput_container_number input:focus {
    outline: 0;
}

.quantity-nav {
    height: 40px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

.quantity-button {
    position: relative;
    cursor: pointer;
    width: 40px;
    height: 40px;

    background-color: transparent;
    background-size: 19px;
    background-repeat: no-repeat;
    background-position: center;
}

.quantity-button.quantity-up {
    position: absolute;
    height: 100%;
    top: 0;
    right: 0;
    background-image: url('../img/icon-plus.svg');
}

.quantity-button.quantity-down {
    position: absolute;
    top: 0;
    height: 100%;
    left: 0;
    // background-size: 19px 2px;
    background-image: url('../img/icon-minus.svg');
}

.response-message {
    @include font-style-paragraph-4;
    width: 100%;
    margin-bottom: 10px;
    padding-left: 10px;

    &.is-error {
        color: $errorColor;
    }
}
