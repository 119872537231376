.content-two-column-wysiwyg {
    margin: 85px 0;

    // &__container {
    // }

    &__title {
        margin-bottom: 27px;
    }

    &__body {
        padding: 0 20px;
    }

    &__grid {
        @include flex-grid(2, 66px, 64px);
    }

    &__text {
        // margin-bottom: 40px;

        p {
            @include font-style-paragraph-2;
        }

        h6 {
            margin-bottom: 5px;
        }
    }

    &__button {
        margin-top: 32px;
    }

    @include respond-to('tablet') {
        margin: 60px 0;

        &__title {
            margin-bottom: 17px;
        }

        // &__body {
        //     padding: 0 20px;
        // }

        &__text {
            max-width: 509px;
        }
    }

    @include respond-to('mobile') {
        margin: 40px 0;

        &__body {
            padding: 0 12px;
        }

        &__grid {
            @include flex-grid(1, 44px, 1px);
            flex-direction: column;
        }

        &__column {
            margin-top: 40px;

            &:nth-child(1) {
                margin-top: 0;
            }
        }

        &__text {
            p {
                @include font-style-paragraph-4;
            }
        }
    }
}
