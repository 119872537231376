//
// Navigation
// --------------------------------------------------

// Primary (Horiztonal) Navigation
// -------------------
/* stylelint-disable */
ul[class*='menu-'] {
    margin: 0;
    padding: 0;

    li {
        position: relative;
        list-style: none;

        a {
            display: inline-block;
        }
    }
}

ul.menu-secondary {
    li {
        display: block;

        a {
            display: block;
        }
    }
}
