.rsvp-payment-form-template,
.rsvp-form-template {
    margin-top: 140px;

    @include respond-to('mobile') {
        margin-top: 80px;
    }
}

.rsvp-form-template-form {
    &__text {
        margin-bottom: 28px;

        // p {
        //     @include font-style-paragraph-4;
        // }
    }

    &__inner {
        max-width: 50%;
        padding-left: 20px;

        padding-bottom: 120px;
    }

    .rsvp-code-form {
        flex-flow: row nowrap;
    }

    .rsvp-code-form__field-group {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;
        width: auto;

        &:first-of-type {
            flex-grow: 999;
        }
    }

    .gfield_html {
        margin: 50px 0 23px;

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            @include font-style-heading-5;
            margin-bottom: 24px;
        }
    }

    @include respond-to('tablet') {
        .rsvp-code-form {
            flex-direction: column;
        }
    }

    @include respond-to('mobile') {
        &__inner {
            max-width: 500px;
            padding-right: 12px;
            padding-left: 12px;
            padding-bottom: 40px;
        }
    }
}
