.feature-theatre-etiquette {
    margin: 70px 0 90px;

    &__items {
        @include flex-grid(3, 20px);
    }

    &__text {
        @include font-style-paragraph-2;
        padding: 0 20px;
        margin-bottom: 40px;
        max-width: 510px;
    }

    @include respond-to('tablet') {
        &__items {
            flex-direction: column;
        }
    }

    @include respond-to('mobile') {
        &__text {
            padding: 0 12px;
        }
    }
}

.theatre-etiquette-teaser {
    min-height: 500px;
    height: 100%;
    padding: 16px 20px;
    background-position: center;
    background-size: cover;
    position: relative;
    border-radius: 5px;
    overflow: hidden;

    &__title {
        @include font-style-heading-3;
        color: $white;
        z-index: 1;
        position: relative;
        max-width: 88%;
    }

    &__text {
        color: $white;
        z-index: 1;
        position: relative;
        opacity: 0;
        transform: translateY(5px);
        margin-top: 20px;
        height: calc(100% - 88px);
        display: flex;
        flex-direction: column;

        transition: all 0.1s ease-in-out 0s;

        &.paragraph-styles p {
            margin-top: 0.6em;
            margin-bottom: 0.6em;
            font-weight: 500;
        }
    }

    &__list {
        display: flex;
        gap: 16px;
        padding: 0;
        margin-top: auto;
        margin-bottom: 32px;
        justify-content: center;
    }

    &__list-item {
        list-style-type: none;

        svg {
            max-width: 100%;
        }
    }

    &::before {
        @include absolute-cover;
        content: '';
        background-color: rgba($black, 0.8);
        z-index: 0;
        opacity: 0.375; // 30% of 0.8
        transition: opacity 0.2s ease-in-out;
    }

    &:hover {
        &::before {
            opacity: 1;
        }

        .theatre-etiquette-teaser__text {
            opacity: 1;
            transform: translateY(0);

            transition: all 0.15s ease-in-out 0.05s;
        }
    }

    @include respond-to('tablet') {
        &__text {
            min-height: 400px;
        }

        &__list {
            margin-bottom: 12px;
        }
    }
}
