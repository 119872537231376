.agenda-template {
    margin: 90px 0 70px;

    &__title {
        margin-bottom: 46px;
    }

    &__items {
        margin-top: 40px;

        .section-title {
            @include font-style-heading-1;
            margin-top: 72px;
            margin-bottom: 0;
        }

        .teaser-agenda-simple {
            &:first-child {
                padding-top: 15px;
            }
        }

        .teaser-agenda-list,
        .section-title {
            display: none;

            &.is-visible {
                // display: block;
            }
        }
    }

    &__buttons {
        margin-top: 40px;
        text-align: center;
    }

    &__button {
        @include button-style--white;
    }

    @include respond-to('tablet') {
        margin: 60px 0 60px;

        &__title {
            margin-bottom: 35px;
        }

        &__items {
            margin-top: 25px;
        }

        &__buttons {
            margin-top: 60px;
        }
    }

    @include respond-to('mobile') {
        margin: 45px 0 40px;

        &__title {
            margin-bottom: 25px;
        }

        &__items {
            margin-top: 25px;
        }

        &__buttons {
            margin-top: 40px;
            padding: 0 10px;
        }

        .button {
            width: 100%;
        }
    }
}
