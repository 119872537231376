.tooltip-svg {
    margin-bottom: 175px;

    &__text {
        padding: 0 20px;
        max-width: 605px;
        margin-bottom: 60px;
    }

    &__container {
        max-width: 700px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 100px;
        position: relative;

        &--large {
            max-width: 900px;
        }
    }

    &__controls {
        position: absolute;
        width: 90vw;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        display: flex;
        justify-content: space-between;
        z-index: 100;
        pointer-events: none;
    }

    &__control {
        background-color: transparent;
        border: 0;
        pointer-events: all;
    }

    &__control &__positioner,
    &__positioner-inner {
        position: relative;
    }

    .marker {
        cursor: pointer;
    }

    @include respond-to('tablet') {
        margin-bottom: 120px;

        &__text {
            padding: 0 12px;
        }

        .tooltip {
            top: auto;
            bottom: -45px;
            width: 100%;
            height: auto;
            // opacity: 1;
            padding-left: 12px;
        }

        .tooltip__inner {
            position: relative;
            left: 0 !important;
            right: 0 !important;
            top: 0 !important;
            bottom: 0 !important;
            transform: translate(0, 0) !important;
            width: 100%;
            text-align: left;
        }

        .tooltip__arrow,
        .tooltip__arrow-left,
        .tooltip__arrow-right,
        .tooltip__arrow-middle {
            display: none;
        }

        .tooltip__text {
            max-width: 100% !important;
        }
    }

    @include respond-to('mobile') {
        margin-bottom: 80px;

        &__controls {
            top: 100%;
        }

        &__control {
            max-width: 50px;
        }
    }
}

.tooltip-svg-overlay {
    padding: 30px 34px;
    margin: 0 auto;
    background-color: $white;
    box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%) scale(0);
    left: 0;
    width: 100%;
    // height: 80%;
    z-index: 999;
    max-height: 80vh;
    overflow: auto;
    transition: opacity 0.15s ease 0.05s, transform 0.15s ease;

    &.is-open {
        opacity: 1;
        pointer-events: all;
        transform: translateY(-50%) scale(1);
    }

    &__inner {
        padding-top: 30px;
        border-top: 1px solid $black;
    }

    &__header {
        display: flex;
        align-items: center;
        margin-bottom: 17px;
        justify-content: space-between;
    }

    &__close {
        background-color: transparent;
        border: 0;
        padding: 0;
    }

    iframe {
        max-width: 100%;
        width: 100%;
    }

    &__quiz-title {
        margin-top: 30px;
    }

    @include respond-to('mobile') {
        padding: 25px 15px;

        &__close {
            top: 15px;
            right: 15px;
        }
    }
}

.quiz-form {
    display: flex;
    flex-direction: column;
    gap: 22px;
    margin-top: 20px;

    & + & {
        margin-top: 5px;
    }

    &__title {
        font-weight: 500;
    }

    &__answer {
        display: flex;
        align-items: center;
        gap: 15px;
    }

    label {
        margin-bottom: 0;
        font-weight: 400;
    }

    &__response {
        display: none;
        align-items: center;
        gap: 8px;
        grid-template-columns: 16px auto;

        svg {
            width: 16px;
            height: 16px;
        }
    }

    &__responses {
        * {
            display: grid;
        }
    }
}
