.search {
    margin: 160px 0 0;

    &__body {
        padding: 36px 0 0;
        margin-bottom: 140px;
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
    }

    &__message {
        text-align: center;
        padding: 50px 0;
    }

    .search-result {
        margin-bottom: 40px;
        position: relative;

        &__title {
            @include font-style-heading-5;
            max-width: 600px;
            margin-bottom: 5px;
            margin-top: 15px;
        }

        &__excerpt {
            @include font-style-paragraph-4;
            max-width: 600px;
        }

        &__link {
            @include absolute-cover;
        }
    }

    @include respond-to('tablet') {
        margin: 95px 0 0;

        &__body {
            padding: 28px 12px 0;
            margin-bottom: 120px;
        }

        .search-result {
            margin-bottom: 30px;
        }
    }

    @include respond-to('mobile') {
        margin: 75px 0 0;

        &__body {
            padding: 19px 12px 0;
            margin-bottom: 80px;
        }

        .search-result {
            margin-bottom: 20px;
        }
    }
}
