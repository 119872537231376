.relational-people {
    margin: 100px 0;

    &__grid {
        @include flex-grid(4, 20px, 34px);
    }

    @include respond-to('tablet') {
        margin: 60px 0;

        &__grid {
            @include flex-grid(3, 10px, 34px);
        }
    }

    @include respond-to('mobile') {
        margin: 40px 0;

        &__grid {
            @include flex-grid(2, 10px, 34px);
        }
    }
}
