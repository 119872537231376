.relational-text-list {
    margin-bottom: 180px;

    &__items {
        @include flex-grid(2, 18px, 0);
    }

    .teaser-text {
        height: 100%;
    }

    @include respond-to('tablet') {
        margin-bottom: 120px;
    }

    @media (min-width: 737px) {
        .relational-text-list__item {
            &:nth-last-child(1),
            &:nth-last-child(2) {
                .teaser-text {
                    border-bottom: 0;
                }
            }
        }
    }

    @include respond-to('mobile') {
        margin-bottom: 80px;

        &__items {
            @include flex-grid(1, 18px, 0);
            flex-direction: column;
        }

        .relational-text-list__item {
            &:nth-last-child(1) {
                .teaser-text {
                    border-bottom: 0;
                }
            }
        }
    }
}
