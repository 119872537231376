.form-newsletter {
    margin: 70px 0 105px;

    &[data-section].closed {
        margin-bottom: 105px;
    }

    // &__container {
    // }

    &__title {
        margin-bottom: 27px;
    }

    &__grid {
        @include flex-grid(2, 44px, 44px);
        padding: 40px 25px 0;
    }

    &__column {
        width: 100%;
        flex-grow: 1;

        &:last-child {
            text-align: right;
            padding-top: 105px;
        }
    }

    &__subtitle {
        @include font-style-heading-5;
        margin-bottom: 25px;
    }

    &__text {
        @include font-style-paragraph-2;

        margin-bottom: 32px;
    }

    .gform_fields {
        display: flex;
        flex-flow: row wrap;
    }

    .gform_footer {
        margin-top: -57px;
        pointer-events: none;
        text-align: right;
        position: relative;
        z-index: 1;

        input[type='submit'] {
            pointer-events: all;
        }
    }

    .social {
        margin-right: 22px;
        display: inline-block;

        &:last-child {
            margin-right: 0;
        }

        .svg__fill {
            fill: $black;
        }

        &:hover {
            .svg__fill {
                fill: $primaryColor;
            }
        }
    }

    @media (max-width: 1220px) {
        .gform_footer {
            margin-top: 0;
            pointer-events: all;
            text-align: left;
        }
    }

    @include respond-to('tablet') {
        margin: 60px 0 70px;

        &[data-section].closed {
            margin-bottom: 70px;
        }

        &__title {
            margin-bottom: 17px;
        }

        .form-newsletter__grid {
            padding: 28px 12px 0;
        }

        .form-newsletter__column {
            &:nth-child(2) {
                display: none;
            }
        }

        .form-newsletter__text {
            max-width: 509px;
        }

        .form-newsletter__subtitle {
            margin-bottom: 15px;
        }

        .gform_footer {
            margin-top: -57px;
            pointer-events: none;
            text-align: right;

            input[type='submit'] {
                pointer-events: all;
            }
        }
    }

    @include respond-to('mobile') {
        margin: 40px 0 60px;

        &[data-section].closed {
            margin-bottom: 60px;
        }

        .form-newsletter__grid {
            padding-top: 19px;
        }

        .gform_footer {
            margin-top: 0;
            pointer-events: all;
            text-align: left;
        }

        .form-newsletter__text {
            @include font-style-paragraph-3;
        }
    }
}
