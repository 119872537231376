.content-two-column-image-text {
    margin: 88px 0;

    &__grid {
        @include flex-grid(2, 44px, 44px);
        padding-top: 35px;
    }

    &__column {
        width: 100%;
        flex-grow: 1;

        &:nth-child(2) {
            display: flex;
            flex-direction: column;
        }
    }

    &__text-header {
        display: flex;
        align-items: center;
        margin-bottom: 30px;
        margin-top: -8px;
        justify-content: space-between;
    }

    &__button {
        margin-top: auto;
    }

    &__text-title {
        @include font-style-heading-5;
    }

    &__text {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .paragraph-styles {
            @include font-style-paragraph-2;
            margin-bottom: 30px;
        }
    }

    @include respond-to('tablet') {
        margin: 60px 0;

        .content-two-column-image-text__column {
            &:first-child {
                display: none;
            }
        }

        .content-two-column-image-text__grid {
            padding: 28px 12px 0;
        }

        .content-two-column-image-text__text-header {
            margin-bottom: 15px;
            margin-top: 0;
        }

        .content-two-column-image-text__text .paragraph-styles {
            margin-bottom: 0;
        }

        .content-two-column-image-text__button {
            margin-top: 30px;
        }
    }

    @include respond-to('mobile') {
        margin: 40px 0;

        .content-two-column-image-text__grid {
            padding-top: 19px;
        }

        &__text {
            .paragraph-styles {
                @include font-style-paragraph-3;
            }
        }
    }
}
