//
// Typography
// --------------------------------------------------

// Helpers
// -------------------

// Html / body
// -------------------
html {
    color: $textColor;
    font-family: $paragraphFont;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 16px;
}

// Headings
// -------------------

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: inherit;
}

h1 {
    @include font-style-heading-1;
}

h2 {
    @include font-style-heading-2;
}

h3 {
    @include font-style-heading-3;
}

h4 {
    @include font-style-heading-4;
}

h5 {
    @include font-style-heading-5;
}

h6 {
    @include font-style-heading-6;
}

.section-title {
    @include font-style-heading-2;
    border-bottom: solid 1px $black;
    padding-bottom: 30px;
    margin-bottom: 40px;

    &--large {
        @include font-style-heading-1;
    }

    @include respond-to('tablet') {
        padding-bottom: 20px;
        margin-bottom: 22px;
    }
}

// Paragraph
// -------------------
p {
    @include font-style-paragraph-2;
    // font-family: $paragraphFont;
    // font-size: 1rem;
}

a {
    color: $actionColor;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

.paragraph-styles {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    ul,
    ol,
    form,
    iframe {
        margin-top: 1.5em;
        margin-bottom: 1.5em;

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    .no-margin {
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .paragraph-1 {
        @include font-style-paragraph-1;
    }

    .paragraph-2 {
        @include font-style-paragraph-2;
    }

    .paragraph-3 {
        @include font-style-paragraph-3;
    }

    .paragraph-4 {
        @include font-style-paragraph-5;
    }

    .paragraph-5 {
        @include font-style-paragraph-5;
    }

    h1 {
        @include font-style-heading-1;
    }

    h2 {
        @include font-style-heading-2;
    }

    h3 {
        @include font-style-heading-3;

        @include respond-to('mobile') {
            @include font-style-heading-5;
        }
    }

    h4 {
        @include font-style-heading-4;
    }

    h5 {
        @include font-style-heading-5;
    }

    h6 {
        @include font-style-heading-6;
    }

    blockquote {
        @include font-style-paragraph-1;
        text-align: center;
        margin-top: 2em;
        padding-left: 30px;
        padding-right: 30px;

        p {
            @include font-style-paragraph-1;
        }
    }

    li {
        @include font-style-paragraph-2;
    }
}

// Basic Elements
// -------------------

abbr,
acronym {
    border-bottom: 1px dotted;
    cursor: help;
}

address {
    font-size: 1rem;
    font-style: italic;
}

blockquote {
    @include font-style-paragraph-1;
    text-align: center;
    margin-top: 2em;
    padding-left: 30px;
    padding-right: 30px;

    p {
        @include font-style-paragraph-1;
    }
}

cite {
    font-style: italic;
}

code {
    font-size: 0.96rem;
    font-family: monospace;
    background: $baseColor;
    border: 1px solid darken($baseColor, 5%);
    padding: 0.125em 0.25em;
    line-height: 150%;
}

em,
i {
    font-style: italic;
}

strong,
b {
    font-weight: bold;
}

hr {
    outline: none;
    border: 0;
    margin: 1em 0;
    padding: 0;
    border-top: 1px solid $baseColor;
}

ol,
ul {
    margin: 1em 0;
    padding: 0 0 0 2em;

    li {
        font-size: 1rem;
        list-style-position: outside;
    }
}

ul li {
    list-style: disc;
}

ol li {
    list-style: decimal;
}

// Form
// -------------------

form {
    margin: 1em 0;
}

// Descriptions
// -------------------

dl {
    margin: 1em 0;

    dt {
        font-weight: bold;
    }

    dd {
        margin: 0 0 0.5em;
    }
}

// Tables
// -------------------

table {
    width: 100%;
    margin: 1em 0;

    th {
        padding: 0.5em;
        border: 1px solid $baseColor;
        background: lighten($baseColor, 3.5%);
        text-align: left;
        font-weight: bold;
    }

    td {
        padding: 0.5em;
        border: 1px solid $baseColor;
    }
}
