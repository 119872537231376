.teaser-webshop {
    cursor: pointer;
    position: relative;

    &.no-link {
        pointer-events: none;
    }

    &__image {
        @include aspect-ratio(295, 368);
        border-radius: 5px;
        margin-bottom: 13px;
        background-color: $gray;
        position: relative;
        overflow: hidden;
        transition: $baseBazier;

        &::before {
            @include absolute-cover;
            content: '';
            display: block;
            background: linear-gradient(178.23deg,
            rgba(0, 0, 0, 0.57) 18.1%,
            rgba(0, 0, 0, 0) 99.03%);
            opacity: 0;
            z-index: 1;
            transition: $baseBazier;
        }
    }

    &__image-inner {
        @include absolute-cover;
        @include background-cover;
        transition: all 700ms cubic-bezier(0.19, 1, 0.22, 1);
    }

    &__label {
        @include font-style-paragraph-3;
        color: $white;
        position: absolute;
        top: 12px;
        left: 20px;
        font-weight: 500;
        transform: translateY(-40px);
        transition: $baseBazier;
        z-index: 2;
        opacity: 0;
    }

    &__name {
        @include font-style-paragraph-2;
        font-weight: 500;
        padding: 0 20px;
        transition: $baseBazier;
    }

    &__position {
        @include font-style-paragraph-4;
        margin-top: -5px;
        padding: 0 20px;
    }

    &__link {
        @include absolute-cover;
        display: block;
        z-index: 1;
    }

    &:hover {
        .teaser-webshop__image {
            border-radius: 5px 5px 5px 100px;
            // box-shadow: 0 4px 2px 0 rgba($black, 0.5);

            &::before {
                opacity: 1;
            }
        }

        .teaser-webshop__image-inner {
            transform: scale(1.1);
        }

        .teaser-webshop__label {
            transform: translateY(0);
            opacity: 1;
        }

        .teaser-webshop__name {
            color: $primaryColor;
        }
    }

    @include respond-to('tablet') {
        &__name {
            @include font-style-paragraph-3;
            font-weight: 500;
        }

        &__position {
            @include font-style-paragraph-5;
        }
    }

    @include respond-to('mobile') {
        &__name,
        &__position {
            padding: 0 12px;
        }

        &__name {
            @include font-style-paragraph-3;
            font-weight: 500;
        }

        &__position {
            @include font-style-paragraph-5;
        }
    }
}
