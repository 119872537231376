.feature-map {
    margin-bottom: 90px;

    &__map-inner {
        @include aspect-ratio(1242, 484);
        position: relative;

        iframe {
            @include absolute-cover;
        }
    }

    &__text {
        @include font-style-paragraph-2;
        padding: 0 20px;
        max-width: 50%;
        margin-bottom: 40px;
    }

    @include respond-to('tablet') {
        margin-bottom: 60px;

        &__text {
            max-width: 100%;
            margin-bottom: 30px;
        }
    }

    @include respond-to('mobile') {
        margin-bottom: 40px;

        &__text {
            @include font-style-paragraph-3;
            padding: 0 12px;
            margin-bottom: 20px;
        }
    }
}
