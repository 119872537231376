.webshop-template {
    padding-top: 90px;

    .header-section__open,
    .header-section__close {
        display: none;
    }

    .header-section--small {
        border-bottom: 0;
    }
}

.webshop-template-products {
    padding-bottom: 150px;

    &__grid {
        @include flex-grid(4, 20px);
    }

    &__column {
        display: none;

        &.is-visible {
            display: block;
        }
    }

    &__buttons {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 77px;
    }

    &__load-more {
        @include button-style--white;
    }

    @include respond-to('tablet') {
        &__grid {
            @include flex-grid(2, 20px);
        }
    }

    @media screen and (max-width: 420px) {
        &__grid {
            @include flex-grid(1, 20px, 20px, column);
        }
    }
}
