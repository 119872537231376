.teaser-text {
    padding: 31px 0;
    border-bottom: solid 1px rgba($black, 0.2);
    position: relative;
    overflow: hidden;

    &__body {
        padding: 0 20px;
        transition: $baseBazier;
    }

    &__title {
        @include font-style-heading-5;
        margin-bottom: 10px;
    }

    &__excerpt {
        @include font-style-paragraph-2;
        max-width: 392px;
    }

    &__button {
        @include button-style--readmore;
        margin-top: 20px;
    }

    &__link {
        @include absolute-cover;
    }

    &:hover {
        color: $primaryColor;

        .teaser-text__body {
            transform: translateX(20px);
        }

        .teaser-text__button {
            @include button-style--readmore-purple;
        }
    }

    @include respond-to('tablet') {
        padding: 21px 0;

        &__body {
            padding: 0 12px;
        }

        &__excerpt {
            @include font-style-paragraph-3;
        }
    }

    @include respond-to('mobile') {
        padding: 19px 0;

        &__button {
            font-size: 12px;

            &::after {
                position: relative;
                top: 1px;
                width: 10px;
            }
        }

        &:hover {
            .teaser-text__body {
                transform: translateX(5px);
            }
        }
    }
}
