.error-page {
    padding: 140px 0 75px;

    .header-section__open,
    .header-section__close {
        display: none;
    }

    .paragraph-styles {
        margin-top: 40px;
    }

    @include respond-to('tablet') {
        .paragraph-styles {
            margin-top: 28px;
        }
    }
}
