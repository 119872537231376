.relational-contact-list {
    margin-bottom: 105px;

    &__container {
        margin-bottom: -40px;
    }

    &__items {
        @include flex-grid(2, 18px, 0);
    }

    @include respond-to('tablet') {
        margin-bottom: 60px;

        &__container {
            margin-bottom: -30px;
        }
    }

    @media (min-width: 737px) {
        .relational-contact-list__item {
            &:nth-last-child(1),
            &:nth-last-child(2) {
                .teaser-contact {
                    border-bottom: 0;
                }
            }
        }
    }

    @include respond-to('mobile') {
        margin-bottom: 40px;

        &__container {
            margin-bottom: -20px;
        }

        &__items {
            @include flex-grid(1, 18px, 0);
            flex-direction: column;
        }

        .relational-contact-list__item {
            &:nth-last-child(1) {
                .teaser-contact {
                    border-bottom: 0;
                }
            }
        }
    }
}
