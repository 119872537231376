.language-switch {
    display: flex;
    align-items: center;
    justify-content: center;

    &__language {
        color: $mediumGray;
        display: inline-block;
        font-weight: 500;
        position: relative;
        top: 1px;

        &.current,
        &:hover {
            color: $white;
            text-decoration: none;
        }

        &.current {
            pointer-events: none;
        }

        &.disabled {
            pointer-events: none;
            // text-decoration: line-through;
        }
    }

    &__seperator {
        display: inline-block;
        height: 17px;
        width: 1px;
        background-color: $white;
        margin: 0 10px;
    }
}
