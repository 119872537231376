.form-contact {
    margin: 85px 0;

    // &__container {
    // }

    &__title {
        margin-bottom: 27px;
    }

    &__grid {
        @include flex-grid(2, 44px, 44px);
        padding: 0 25px;
    }

    &__column {
        width: 100%;
        flex-grow: 1;

        &:last-child {
            text-align: right;
        }
    }

    &__subtitle {
        @include font-style-heading-5;
        margin-bottom: 25px;
    }

    &__text {
        @include font-style-paragraph-2;
        margin-bottom: 40px;
    }

    .gform_fields {
        // display: flex;
        // flex-flow: row wrap;
    }

    .social {
        margin-right: 22px;
        display: inline-block;

        &:last-child {
            margin-right: 0;
        }

        .svg__fill {
            fill: $black;
        }
    }

    @include respond-to('tablet') {
        margin: 60px 0;

        &__title {
            margin-bottom: 17px;
        }

        &__grid {
            padding: 0 12px;
        }

        &__column {
            &:nth-child(2) {
                display: none;
            }
        }

        &__text {
            max-width: 509px;
        }

        &__subtitle {
            margin-bottom: 15px;
        }
    }

    @include respond-to('mobile') {
        margin: 40px 0;

        &__text {
            @include font-style-paragraph-3;
        }
    }
}
