@use 'sass:math';

.header-word-carousel {
    @include background-cover;
    color: $white;
    position: relative;
    border-radius: 0 0 0 140px;
    overflow: hidden;

    position: relative;

    &::before {
        display: block;
        content: '';
        width: 100%;
        padding-top: math.div(250, 599) * 100%;
    }

    > .inner {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    &__image {
        @include background-cover;
        @include absolute-cover;
    }

    &__image--mobile {
        display: none;
    }

    &__container {
        display: flex;
        flex-direction: column;
        padding: 85px 0 46px;
        min-height: 100%;
    }

    &__logo {
        margin-bottom: 100px;
        display: none;
        z-index: 1;
    }

    &__content {
        margin-top: auto;
        position: relative;
        z-index: 1;
    }

    &__button {
        margin-bottom: 5px;

        &:first-child {
            @include button-style--ghost;
        }

        &:last-child {
            @include button-style--white;
        }
    }

    &__title {
        @include font-style-heading-4;
    }

    &__subtitle {
        @include font-style-heading-5;
        font-weight: 400;
        max-width: 550px;
    }

    &__terms {
        margin-top: 7px;
    }

    &__text {
        @include font-style-paragraph-3;
        font-weight: 500;
        max-width: 430px;
        margin-bottom: 10px;
    }

    &__buttons {
        margin-top: 22px;
    }

    &__button {
        margin-right: 15px;

        &:last-child {
            margin-right: 0;
        }
    }

    &__words {
        @include font-style-heading-2;
    }

    &__words-container {
        --word-height: 60px;
        max-height: var(--word-height);
        overflow: hidden;
        text-transform: uppercase;
    }

    &__words-container-inner {
        transition: transform 200ms ease;
        pointer-events: none;
    }

    &::after {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        display: block;
        content: '';
        background: linear-gradient(
                0deg,
                rgba(0, 0, 0, 0.8) -16.79%,
                rgba(0, 0, 0, 0) 29.01%
            ),
            linear-gradient(
                180deg,
                rgba(0, 0, 0, 0.8) -22.63%,
                rgba(0, 0, 0, 0) 33.3%
            );
        height: 100%;
    }

    @media (max-height: 500px) {
        &__container {
            min-height: calc(var(--vh, 1vh) * 90);
        }
    }

    @include respond-to('tablet') {
        border-radius: 0 0 0 80px;

        &__words-container {
            --word-height: 40px;
        }
    }

    @include respond-to('mobile') {
        border-radius: 0 0 0 55px;

        &__container {
            min-height: calc(var(--vh, 1vh) * 90);
        }

        // &__text {
        //     display: none;
        // }

        &__image {
            display: none;
        }

        &__image--mobile {
            display: block;
        }

        &__words-container {
            --word-height: 30px;
        }
    }
}

.header-word-carousel--fullscreen {
    &::after {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        display: block;
        content: '';
        background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.57) 24.59%,
            rgba(0, 0, 0, 0) 100%
        );
        padding-bottom: 23.194444442%;
        height: auto;
    }

    &::before {
        padding-top: calc(100 * var(--vh, 1vh));
    }

    .header-word-carousel__container {
        min-height: calc(100 * var(--vh, 1vh));
    }

    .header-word-carousel__logo {
        display: block;
    }

    @include respond-to('mobile') {
        .header-word-carousel__container {
            min-height: calc(100 * var(--vh, 1vh));
        }
    }
}

.header-word-carousel--testimonial {
    .header-basic__container {
        align-items: end;
        justify-content: center;
    }

    .header-word-carousel__content {
        margin-top: 0;
    }
}

.header-word-carousel--testimonial.header-word-carousel--flipped {
    border-radius: 0 0 140px;

    .header-word-carousel__container {
        align-items: start;
    }
}

.template-page__flex-components .header-word-carousel {
    margin: 170px 0;

    @include respond-to('tablet') {
        margin: 120px 0;
    }

    @include respond-to('mobile') {
        margin: 80px 0;
    }
}

.template-page__flex-components .header-word-carousel::before {
    padding-top: 30%;
}
