.page-template-archive-programme-template {
    .programme-template__items-title {
        flex-basis: 100%;

        margin: 0 10px 30px;
    }

    .programme-template [data-view='grid'] .teaser-programme-grid {
        max-width: calc(33% - 20px);

        &:nth-child(3n) {
            @include aspect-ratio(610, 604);
            max-width: calc(33% - 20px);
        }

        @include respond-to('tablet') {
            max-width: calc(50% - 20px);

            &:nth-child(3n) {
                @include aspect-ratio(1240, 400);
                max-width: calc(50% - 20px);
            }
        }

        @include respond-to('mobile') {
            max-width: calc(100% - 20px);

            &:nth-child(3n) {
                max-width: calc(100% - 20px);
            }
        }
    }
}
