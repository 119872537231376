.contact-template {
    margin: 80px 0 70px;

    @include respond-to('tablet') {
        margin: 60px 0 60px;
    }

    @include respond-to('mobile') {
        margin: 45px 0 40px;
    }
}
