.header-section {
    border-bottom: solid 1px $black;
    padding: 29px 0;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 1;

    &__title {
        @include font-style-heading-1;
        margin-right: 20px;
    }

    &__date {
        @include font-style-paragraph-5;
        margin-bottom: 28px;
        display: block;
        font-style: italic;
    }

    &__open,
    &__close {
        position: relative;
        width: 24px;
        height: 24px;
        min-width: 24px;
        cursor: pointer;
        padding: 0;
        background: transparent;
        padding: 0;
        outline: none;
        box-shadow: none;
        border: 0;
        margin-left: auto;
        transition: all 100ms ease;

        &::after,
        &::before {
            content: '';
            display: block;
            position: absolute;
            width: 100%;
            height: 2px;
            background-color: $black;
            top: 50%;
            transition: all 100ms ease-in-out;
        }

        &::before {
            transform: rotate(0);

            .closed & {
                transform: rotate(90deg);
            }
        }
    }

    &__open {
        opacity: 0;
        pointer-events: none;
        margin-right: 20px;

        .closed & {
            opacity: 1;
            pointer-events: all;
        }
    }

    &__close {
        position: absolute;
        bottom: -60px;
        right: 20px;

        .closed & {
            opacity: 0;
            pointer-events: none;
        }
    }

    @include respond-to('tablet') {
        padding: 17px 0;

        &__open {
            opacity: 1;
            pointer-events: all;
        }

        &__close {
            opacity: 0;
        }
    }

    @include respond-to('mobile') {
        padding: 14px 0;

        &__open,
        &__close {
            width: 15px;
            height: 15px;
            min-width: 15px;
            margin-right: 12px;
        }
    }
}

.header-section--small {
    .header-section__title {
        @include font-style-heading-2;
    }
}

.header-section--no-padding-top {
    padding-top: 0;

    .header-section__open {
        margin-top: 14px;

        @include respond-to('tablet') {
            margin-top: 2px;
        }
    }
}

.header-section--collapse-position-top {
    .header-section__open {
        opacity: 1;
        pointer-events: all;
    }

    .header-section__close {
        opacity: 0;
        pointer-events: none;
    }
}
