@font-face {
    font-family: 'Neue Montreal';
    src: url('../fonts/NeueMontreal-Italic.eot');
    src: url('../fonts/NeueMontreal-Italic.eot?#iefix')
        format('embedded-opentype'),
        url('../fonts/NeueMontreal-Italic.woff2') format('woff2'),
        url('../fonts/NeueMontreal-Italic.woff') format('woff'),
        url('../fonts/NeueMontreal-Italic.ttf') format('truetype'),
        url('../fonts/NeueMontreal-Italic.svg#NeueMontreal-Italic')
        format('svg');
    font-weight: 400;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Neue Montreal';
    src: url('../fonts/NeueMontreal-Regular.eot');
    src: url('../fonts/NeueMontreal-Regular.eot?#iefix')
        format('embedded-opentype'),
        url('../fonts/NeueMontreal-Regular.woff2') format('woff2'),
        url('../fonts/NeueMontreal-Regular.woff') format('woff'),
        url('../fonts/NeueMontreal-Regular.ttf') format('truetype'),
        url('../fonts/NeueMontreal-Regular.svg#NeueMontreal-Regular')
        format('svg');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Neue Montreal';
    src: url('../fonts/NeueMontreal-Bold.eot');
    src: url('../fonts/NeueMontreal-Bold.eot?#iefix')
        format('embedded-opentype'),
        url('../fonts/NeueMontreal-Bold.woff2') format('woff2'),
        url('../fonts/NeueMontreal-Bold.woff') format('woff'),
        url('../fonts/NeueMontreal-Bold.ttf') format('truetype'),
        url('../fonts/NeueMontreal-Bold.svg#NeueMontreal-Bold') format('svg');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Neue Montreal';
    src: url('../fonts/NeueMontreal-Medium.eot');
    src: url('../fonts/NeueMontreal-Medium.eot?#iefix')
        format('embedded-opentype'),
        url('../fonts/NeueMontreal-Medium.woff2') format('woff2'),
        url('../fonts/NeueMontreal-Medium.woff') format('woff'),
        url('../fonts/NeueMontreal-Medium.ttf') format('truetype'),
        url('../fonts/NeueMontreal-Medium.svg#NeueMontreal-Medium')
        format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'social';
    src: url('../fonts/social.eot');
    src: url('../fonts/social.eot?#iefix') format('embedded-opentype'),
        url('../fonts/social.woff') format('woff'),
        url('../fonts/social.ttf') format('truetype'),
        url('../fonts/social.svg#social') format('svg');
    font-weight: normal;
    font-style: normal;
}

[data-icon]::before {
    font-family: 'social' !important;
    content: attr(data-icon);
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

[class^='icon-']::before,
[class*=' icon-']::before {
    font-family: 'social' !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-twitter::before {
    content: '\61';
}

.icon-linkedin::before {
    content: '\62';
}

.icon-envelope::before {
    content: '\63';
}

.icon-facebook::before {
    content: '\64';
}

.icon-youtube::before {
    content: '\65';
}

.icon-pinterest-p::before {
    content: '\66';
}
