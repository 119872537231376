.feature-carousel-people {
    overflow: hidden;

    &__title {
        @include font-style-heading-5;
        margin-bottom: 34px;
        margin-top: 100px;
        padding: 0 20px;

        &:first-child {
            margin-top: 0;
        }
    }

    &__chreographers,
    &__dancers {
        margin-bottom: 200px;
    }

    &__carousel {
        position: relative;
    }

    &__swiper {
        overflow: visible;
    }

    &__prev,
    &__next {
        background-color: $white;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
        height: 85px;
        width: 85px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 35%;
        z-index: 2;
        cursor: pointer;

        &.swiper-button-disabled {
            display: none;
        }

        &::after {
            content: get-icon-chevron-right-large($black);
            display: block;
            width: 20px;
            height: 37px;
            margin-left: 4px;
        }
    }

    &__prev {
        left: -53px;

        &::after {
            transform: rotate(180deg);
        }
    }

    &__next {
        right: -53px;
    }

    @include respond-to('tablet') {
        &__chreographers,
        &__dancers {
            margin-bottom: 120px;
        }

        &__prev,
        &__next {
            display: none;
        }

        &__title {
            margin-bottom: 22px;
            padding: 0 12px;
        }

        &__chreographers {
            margin-bottom: 80px;
        }
    }

    @include respond-to('mobile') {
        &__chreographers,
        &__dancers {
            margin-bottom: 80px;
        }

        &__carousel {
            // padding-right: 30%;
        }

        &__title {
            margin-bottom: 13px;
        }

        &__chreographers {
            margin-bottom: 60px;
        }
    }
}
