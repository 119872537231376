//
// Tooltips
// --------------------------------------------------

[data-tooltip] {
    position: relative;
    height: 100%;
    cursor: pointer;
    display: inline-block;

    &::before,
    &::after {
        transition: all 0.2s ease-out;
        position: absolute;
        visibility: hidden;
        opacity: 0;
        pointer-events: none;
    }

    &::before {
        @include arrow-down(7px, $textColor);
        content: '';
        top: 0;
        left: 50%;
        margin-left: -3.25px;
        z-index: 10001;
        bottom: -10px;
    }

    &::after {
        box-sizing: border-box;
        content: attr(data-tooltip);
        padding: 0.5em 0.75em;
        border-radius: 3px;
        font-size: 0.95em;
        z-index: 10000;
        left: 50%;
        bottom: 100%;
        margin-left: -7.5em;
        margin-top: 10px;
        width: 15em;
        line-height: 120%;
        background: $textColor;
        color: $baseColor;
    }

    &:hover {
        &::before,
        &::after {
            visibility: visible;
            opacity: 1;
        }
    }
}
