.teaser-agenda-simple {
    border-bottom: solid 1px rgba($black, 0.2);

    &:first-child {
        padding-top: 0;
    }

    &__inner {
        display: flex;
        align-items: center;
        padding: 15px 20px;
    }

    &__column {
        width: 20%;
        flex-grow: 1;
        margin-right: 20px;

        &:last-child {
            text-align: right;
            margin-right: 0;
        }

        &:nth-child(3) {
            text-align: right;
        }
    }

    &__date {
        @include font-style-heading-6;

        @include only-dutch {
            text-transform: lowercase;
        }

        span {
            display: block;
            margin-top: -6px;
        }

        &--mobile {
            display: none;
        }
    }

    &__location {
        @include font-style-heading-6;
    }

    &__city-time {
        @include font-style-heading-6;
        font-weight: 400;

        &--mobile {
            display: none;
        }
    }

    &__terms {
        margin-top: 5px;

        .tag {
            color: $primaryColor;
            border-color: $primaryColor;
        }
    }

    @include respond-to('tablet') {
        &__inner {
            flex-flow: row wrap;
        }

        &__column {
            width: 40%;

            &:nth-child(2) {
                text-align: right;
                margin-right: 0;
            }

            &:nth-child(3) {
                text-align: left;
            }

            &:last-child {
                margin-top: 15px;
            }
        }
    }

    @include respond-to('mobile') {
        &__column {
            &:last-child {
                // text-align: left;
                margin-top: 10px;
            }
        }

        &__date {
            span {
                margin-top: -2px;
                display: inline-block;
            }
        }
    }

    @include respond-to('mobile') {
        &__column {
            width: 100%;

            &:nth-child(2) {
                text-align: left;
            }

            &:nth-child(3) {
                text-align: left;
                margin-top: 15px;
                display: none;
            }

            &:last-child {
                text-align: left;
                margin-top: 15px;
            }
        }

        &__location {
            // @include font-style-heading-5;
            margin-top: 5px;
        }

        &__city-time,
        &__date {
            display: none;

            &--mobile {
                display: inline-block;
            }
        }

        &__date--mobile {
            margin-right: 3px;
        }
    }

    @media (max-width: 500px) {
        .button {
            width: 100%;
        }
    }
}
