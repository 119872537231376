.relational-agenda-list {
    margin: 70px 0 100px;

    &__title {
        @include font-style-heading-1;
        margin-bottom: 25px;
    }

    &__buttons {
        padding-top: 50px;
        text-align: center;
    }

    &__button {
        @include button-style--white;
    }

    .teaser-agenda-simple {
        &:first-child {
            padding-top: 15px;
        }
    }

    @include respond-to('tablet') {
        margin: 35px 0 50px;

        &__buttons {
            padding-top: 30px;
        }
    }

    @include respond-to('mobile') {
        margin: 35px 0 40px;
    }
}
