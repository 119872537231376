.site-footer {
    background-color: $dark;
    border-radius: 120px 0 0;
    color: $white;

    &__container {
        padding: 48px 0 20px;
    }

    &__logo {
        display: block;
        max-width: 608px;
        margin-bottom: 35px;
    }

    &__title {
        @include font-style-heading-6;
        font-weight: 500;
        margin-bottom: 30px;
    }

    &__language-switch-title,
    &__language-switch {
        display: none;
    }

    &__contact {
        @include font-style-paragraph-3;
    }

    &__columns,
    &__footer {
        @include flex-grid(4, 30px, 30px);
    }

    &__menu {
        padding: 0;
        margin: 0;
    }

    &__menu-item {
        @include font-style-paragraph-3;
        list-style: none;
    }

    &__menu-link {
        color: $white;
    }

    &__footer {
        margin-top: 40px;
    }

    &__footer-link,
    &__disclaimer {
        @include font-style-paragraph-4;
        color: $white;
    }

    .social {
        margin-right: 24px;
        display: inline-block;

        &:last-child {
            margin-right: 0;
        }
    }

    @include respond-to('tablet') {
        border-radius: 70px 0 0;

        &__logo {
            max-width: 352px;
        }

        &__column {
            &:nth-child(1) {
                flex: 0 0 50%;
            }

            &:last-child {
                display: none;
            }
        }

        &__footer {
            // flex-flow: row wrap;
        }

        &__disclaimer,
        &__footer-link {
            order: 2;
            flex: 0 0 50%;
        }

        &__footer-link {
            flex: 0 0 25%;
        }

        &__socials {
            order: 1;
            flex: 0 0 100%;
        }
    }

    @include respond-to('mobile') {
        border-radius: 20px 0 0;

        &__container {
            padding: 23px 10px 18px;
        }

        &__language-switch-title,
        &__language-switch {
            display: block;
        }

        &__logo {
            max-width: 280px;
        }
    }

    @media (max-width: 550px) {
        &__columns {
            display: block;
        }

        &__footer {
            margin-top: 5px;
        }

        &__disclaimer,
        &__footer-link {
            order: 2;
            flex: 0 0 100%;
        }

        &__footer-link {
            flex: 0 0 50%;
        }

        &__socials {
            text-align: center;
        }
    }
}

.site-footer .language-switch {
    justify-content: flex-start;
    margin-bottom: 40px;
}
